import * as React from 'react';
import { useNavigate } from 'react-router';
import DataApi from '../../api/DataApi';
import CenteredForm from '../../components/centered-form/CenteredForm';
import DownloadSummaryButton from '../../components/download-summary/DownloadSummary';
import PageHeader from '../../components/page-header/PageHeader';
import TitleLineComponent from '../../components/page-layout/TitleLineComponent';
import FormStepper from '../form-stepper/FormStepper';

import style from './MooringDonePage.module.css';

const MooringDonePage = () => {
    const navigate = useNavigate();

    const handleOnClick = () => {
        console.log('getting summary');
        DataApi.getMooringSummary((json: any)=>{
            window.open(json.summary_link);
        }, (error: Error)=>{
            console.error(error);
        })
    }


    return (
        <React.Fragment>
            <PageHeader className={style.pageHeader}></PageHeader>
            <div id={style.container}>
                <CenteredForm>
                <TitleLineComponent text={"6 of 24 uur afmeren kopen"} />
                <div id={style.header}>Bedankt</div>
                        <div id={style.subHeader}>Wij hebben uw aanvraag ontvangen op {new Date().toLocaleDateString('nl-NL', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}.</div>
                        <div>
                            U ontvangt een e-mail met de bevestiging van het afmeren. 1 uur van tevoren krijgt u een sms en een e-mail dat de periode waarin u mag afmeren bijna afloopt.
                            <br></br>
                            <br></br>
                        </div>
                        <div className={style.pageSubHeader}>Samenvatting</div>
                        <div>U kunt de samenvatting van uw aanvraag hieronder downloaden.</div>
                        <DownloadSummaryButton onClick={handleOnClick}></DownloadSummaryButton>
                        <br />
                        <div className={style.pageSubHeader}>Vragen?</div>
                        <div>   
                            Bel het telefoonnummer 14 020, maandag tot en met vrijdag van 08.00 tot 18.00 uur. Of gebruik het contactformulier op de website.
                            <br></br>
                            Houd uw vignetnummer bij de hand.
                        </div>
                </CenteredForm>
            </div>
        </React.Fragment>
    )
}

export default MooringDonePage;