import { stringify } from 'querystring';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router';
import DataApi, { TDriveType, TPaymentType } from '../../api/DataApi';
import UserApi, { TOwner } from '../../api/UserApi';
import VignetApi, { TVignet } from '../../api/VignetApi';
import AlertBox, { ESeverity } from '../../components/alert-box/AlertBox';
import CenteredForm from '../../components/centered-form/CenteredForm';
import ChangeVignetPageCalculations from '../../components/change-vignet-page-calculations/ChangeVignetPageCalculations';
import ChangeVignetPagePaymentDetails from '../../components/change-vignet-page-payment-details/ChangeVignetPagePaymentDetails';
import ChangeVignetPagePaymentTerms from '../../components/change-vignet-page-payment-terms/ChangeVignetPagePaymentTerms';
import ChangeVignetPageSummary, { TChangeVignetPageSummary } from '../../components/change-vignet-page-summary/ChangeVignetPageSummary';
import KeyValuePair, { EEditMode, TEditOptions } from '../../components/key-value-pair/KeyValuePair';
import PageHeader from '../../components/page-header/PageHeader';
import UploadField, { TFile } from '../../components/upload-field/UploadField';
import FormStepper, { TStep } from '../form-stepper/FormStepper';

import style from './ChangeVignetPage.module.css';

const ChangeVignetPage = () => {
    const navigate = useNavigate();
    const params = useParams();

    const [expansion, setExpansion] = React.useState<number>(0);

    const [iban, setIban] = React.useState<string | undefined>(undefined);
    const [ibanHolder, setIbanHolder] = React.useState<string | undefined>(undefined);

    const [paymentMethod, setPaymentMethod] = React.useState<TPaymentType | undefined>(undefined);

    const [pageError, setPageError] = React.useState<string | undefined>(undefined);

    const [fileUploadText, setfileUploadText] = React.useState<string | undefined>(undefined);

    const [hasAgreedPaymentTerms, setHasAgreedPaymentTerms] = React.useState<boolean | undefined>(undefined);
    const [hasAdditionalFields, setHasAdditionalFields] = React.useState<boolean>(false);

    const [validity, setValidity] = React.useState<boolean>(true);

    const [hasAgreedDetailsCorrect, setHasAgreedDetailsCorrect] = React.useState<boolean>(false);
    const [hasAgreedUserAgreement, setHasAgreedUserAgreement] = React.useState<boolean>(false);

    const [editMode, setEditMode] = React.useState<boolean>(false);

    const [fileUploadMarina, setFileUploadMarina] = React.useState<TFile[]>([]);
    const [fileUploadBoat, setFileUploadBoat] = React.useState<TFile[]>([]);

    const [uploadingFiles, setUploadingFiles] = React.useState<boolean>(false);

    const [staticMode, setStaticMode] = React.useState<boolean>(false);

    const [returnVignetID, setReturnVignetID] = React.useState<string | undefined>(undefined);
    const [returnPaymentMethodID, setReturnPaymentMethodID] = React.useState<string | undefined>(undefined);

    const [locked, setLocked] = React.useState<boolean>(false);

    const getVignet = (vignetID: string) => {
        VignetApi.getVignet(vignetID, (vignet: TVignet) => {
            setVignet(vignet);
        }, (error: Error) => {
            console.error(error);
        });
    }

    const [vignet, setVignet] = React.useState<TVignet | undefined>(() => {
        if (params.vignetID) {
            getVignet(params.vignetID);
        }
        return undefined;
    });

    const [owner, setOwner] = React.useState<TOwner | undefined>(() => {
        UserApi.getOwner((json: any) => {
            setOwner(json);
        }, (error: Error) => {
            console.error(error);
        });
        return undefined;
    });

    const [driveTypes, setDriveTypes] = React.useState<TDriveType[] | undefined>(() => {
        DataApi.getDriveTypes((driveTypes: TDriveType[]) => {
            setDriveTypes(driveTypes);
        }, (error: Error) => {
            console.error(error);
        })
        return undefined;
    })

    if (params.data && params.status && !returnVignetID) {
        const data = JSON.parse(window.atob(params.data));
        setReturnVignetID(data.vignetID);
        setReturnPaymentMethodID(data.paymentMethodID);
        setIban(data.iban);
        setIbanHolder(data.ibanHolder);
        setExpansion(3);
        getVignet(data.vignetID);
        setStaticMode(true);
        return (<div>
            Redirecting..
        </div>)
    }

    if (!vignet || !owner) {
        return (
            <div>Loading..</div>
        )
    }

    const handleOnNext = () => {
        setPageError(undefined);
        setValidity(true);

        if (expansion === 0) {
            if (areFieldsValid()) {
                VignetApi.updateVignet(vignet.vignet_id, vignet.object_length, vignet.object_width, vignet.object_marina, vignet.object_drive_type_id, () => {
                    setEditMode(false);
                    setValidity(true);
                    VignetApi.getVignet(vignet.vignet_id, (vignet: TVignet) => {
                        setVignet(vignet);
                        setExpansion(expansion + 1);
                        setPageError(undefined);
                    }, (error: Error) => {
                        setPageError(error.message);
                    })
                }, (error: Error) => {
                    setPageError(error.message);
                })
                return;
            } else {
                setValidity(false);
                return;
            }
        }

        setEditMode(false);

        const isGreenVignet = vignet.vignet_type === 'Hybride' || vignet.vignet_type === 'Milieu';

        if (expansion === 1) {
            if (!isGreenVignet && vignet.documents?.find((entry: string)=>{return entry === 'Ligplaatsbewijs'}) && !fileUploadMarina) {
                setValidity(false);
                return;
            }
            if (parseInt(vignet.object_length) >= 10 && !fileUploadBoat) {
                setValidity(false);
                return;
            }
        }

        if (expansion === 2 && !paymentMethod) {
            setValidity(false);
            return;
        }

        if (expansion === 2 && hasAdditionalFields && (!iban || !ibanHolder)) {
            setValidity(false);
            return;
        }

        if (expansion === 2 && hasAdditionalFields && !hasAgreedPaymentTerms) {
            setValidity(false);
            return;
        }

        if (expansion <= 2) {
            setExpansion(expansion + 1);
            return;
        }

        if (expansion === 3) {
            if (!hasAgreedDetailsCorrect || !hasAgreedUserAgreement) {
                setPageError('Ga akkoord met het bovenstaande om verder te gaan.');
                setValidity(false);
                return;
            }
            if (parseInt(vignet.object_length) >= 10 && fileUploadBoat.length === 0) {
                setValidity(false);
                setPageError('U moet een bestand toevoegen.')
                return;
            }
            if (!isGreenVignet && vignet.documents?.find((entry: string)=>{return entry === 'Ligplaatsbewijs'}) && fileUploadMarina.length === 0) {
                setValidity(false);
                setPageError('U moet een bestand toevoegen.')
                return;
            }
            setPageError(undefined);
        }

        const documents: string[] = [];
        if (fileUploadBoat.length > 0) {
            fileUploadBoat.forEach((file: TFile) => {
                documents.push(file.data);
            })
        }
        if (fileUploadMarina.length > 0) {
            fileUploadMarina.forEach((file: TFile) => {
                documents.push(file.data);
            })
        }

        if (!vignet) {
            setPageError('Geen vignet.');
            return;
        }
        if (!paymentMethod) {
            setPageError('Geen betaalmethode.');
            return;
        }

        if (documents.length > 0) {
            setUploadingFiles(true);
                DataApi.uploadDocuments(params.vignetID || '', documents, () => {
                    VignetApi.extendVignet(vignet, paymentMethod, iban, ibanHolder, (json: any) => {
                    if (!json.redirect_url || (json.redirect_url as string).endsWith(window.location.host + '/done')) {
                        navigate('done');
                    } else {
                        window.location = json.redirect_url;
                    }
                }, (error: Error) => {
                    console.error(error.message);
                    setPageError(error.message);
                    setUploadingFiles(false);
                })
            }, (error: Error) => {
                console.log(error);
                setPageError(error.message);
                setUploadingFiles(false);
            })
        } else {
            setLocked(true);
            VignetApi.extendVignet(vignet, paymentMethod, iban, ibanHolder, (json: any) => {
                if (!json.redirect_url || (json.redirect_url as string).endsWith(window.location.host + '/done')) {
                    navigate('done');
                } else {
                    window.location = json.redirect_url;
                }
            }, (error: Error) => {
                console.log(error);
                setPageError(error.message);
                setUploadingFiles(false);
                setLocked(false);
            })
        }
    };

    const handleOnPrevious = () => {
        if (expansion > 0) {
            setExpansion(expansion - 1);
            return;
        }
        navigate('/vignet-overview');
    };

    const getDriveTypeName = () => {
        return driveTypes?.find((driveType: TDriveType) => { return driveType.drive_type_id === vignet.object_drive_type_id })?.drive_type_name || '';
    }

    const handleChangePaymentMethod = (paymentType: TPaymentType, iban: string | undefined, ibanHolder: string | undefined, hasAgreed: boolean, hasAdditionalFields: boolean) => {
        setHasAgreedPaymentTerms(hasAgreed);
        setHasAdditionalFields(hasAdditionalFields);
        setPaymentMethod(paymentType);
        setIban(iban);
        setIbanHolder(ibanHolder);
    }

    const getVignetCost = () => {
        return vignet.vignet_cost;
    }

    const getSummary = () => {
        const summary: TChangeVignetPageSummary = {
            bsn: owner.bsn,
            initials: owner.initials,
            prefix: owner.prefix,
            lastName: owner.lastname,
            street: owner.address,
            houseNumber: owner.number,
            addition: owner.addition,
            zipCode: owner.postcode,
            city: owner.city,
            phoneNumber: owner.mobilephone,
            email: owner.email,
            vignetNumber: vignet.vignet_number,
            objectName: vignet.object_name,
            driveType: getDriveTypeName(),
            mooring: vignet.object_marina,
            vignetType: vignet.vignet_type_display_name,
            totalCost: getVignetCost(),
            paymentType: paymentMethod?.name || '',
            setHasAgreedUserAgreement: setHasAgreedUserAgreement,
            setHasAgreedDetailsCorrect: setHasAgreedDetailsCorrect,
            setPageError: setPageError,
            defaultHasAgreedDetailsCorrect: hasAgreedDetailsCorrect,
            defaultHasAgreedUserAgreement: hasAgreedUserAgreement,
            validity: validity
        }
        return summary;
    }

    console.log(vignet);

    const steps: TStep[] = [
        { text: 'Uw gegevens' },
        { text: 'Vignetoverzicht' },
        { text: 'Wijzigen ' + vignet.vignet_number || '' },
        { text: 'Bedankt' }
    ]

    const areFieldsValid = () => {
        let valid = true;
        if (vignet.object_length.length <= 0) {
            valid = false;
        }
        if (vignet.object_width.length <= 0) {
            valid = false;
        }
        if (vignet.object_drive_type_id.length <= 0) {
            valid = false
        }
        if (vignet.object_marina.length <= 0) {
            valid = false
        }
        return valid;
    }

    const handleOnChangeLength = (e: any) => {
        vignet.object_length = e.target.value;
        return vignet.object_length.length > 0;
    }

    const handleOnChangeWidth = (e: any) => {
        vignet.object_width = e.target.value;
        return vignet.object_width.length > 0;
    }

    const handleOnChangeDriveType = (e: any) => {
        vignet.object_drive_type_id = e.target.value;
        return vignet.object_drive_type_id.length > 0;
    }

    const handleOnChangeMarina = (e: any) => {
        vignet.object_marina = e.target.value;
        return vignet.object_marina.length > 0;
    }

    const buildDriveTypes = () => {
        return driveTypes?.map((driveType: TDriveType, index: number) => {
            return { text: driveType.drive_type_name, value: driveType.drive_type_id }
        })
    }

    const driveTypeEditOptions: TEditOptions = {
        mode: EEditMode.DROPDOWN,
        default: driveTypes?.findIndex((entry: TDriveType) => { return entry.drive_type_id === vignet.object_drive_type_id }),
        options: buildDriveTypes() || []
    }

    const marinaEditOptions: TEditOptions = {
        mode: EEditMode.RADIO,
        default: vignet.object_marina === '0' ? 0 : 1,
        options: [
            { text: 'Nee', value: '0' },
            { text: 'Ja', value: '1' }
        ]
    }

    const getPaymentStatusDOM = () => {
        switch (params.status) {
            case 'cancel':
                return <AlertBox severity={ESeverity.HIGH} header={'De betaling is mislukt.'} text={'Probeer het opnieuw of kies een andere manier om te betalen.'}></AlertBox>
            case 'reject':
                return <AlertBox severity={ESeverity.HIGH} header={'De betaling is mislukt.'} text={'Probeer het opnieuw of kies een andere manier om te betalen.'}></AlertBox>
            case 'success':
                return <AlertBox severity={ESeverity.LOW} header={'De betaling is gelukt.'} text={''}></AlertBox> // Unused.
            case 'error':
                return <AlertBox severity={ESeverity.HIGH} header={'De betaling is mislukt.'} text={'Probeer het opnieuw of kies een andere manier om te betalen.'}></AlertBox>
        }
        return (<React.Fragment></React.Fragment>)
    }

    const readFile = async (file: any) => {
        return await new Promise((resolve: any) => {
            const fileReader = new FileReader();
            fileReader.onload = () => { resolve({ name: file.name, data: (fileReader.result as string).split(';base64,', 2)[1] }) }
            fileReader.readAsDataURL(file);
        });
    }

    const removeFile = (index: number, files: TFile[], setter: React.Dispatch<React.SetStateAction<TFile[]>>) => {
        files.splice(index, 1);
        setter([...files])
    }

    const handleOnChangeFileUploadMarina = async (e: any) => {
        setPageError(undefined);
        const files: TFile[] = [...fileUploadMarina];
        for (let i = 0; i < e.target.files.length; i++) {
            files.push(await readFile(e.target.files[i]) as TFile);
        }
        setFileUploadMarina(files);
        setValidity(true);
    }

    const handleOnChangeFileUploadBoat = async (e: any) => {
        setPageError(undefined);
        const files: TFile[] = [...fileUploadBoat];
        for (let i = 0; i < e.target.files.length; i++) {
            files.push(await readFile(e.target.files[i]) as TFile);
        }
        setFileUploadBoat(files);
        setValidity(true);
    }

    const getVignetTypeWarningDOM = () => {
        // switch (vignet.vignet_type.toLocaleLowerCase()) {
        //     case 'milieu':
        //         return <AlertBox header={'Let op: u krijgt een milieu (groen) vignet.'} text={'Uit uw gegevens blijkt dat u recht hebt op een milieu (groen) vignet. Dit vignet is alleen geldig als uw boot een elektrische motor of helemaal geen motor heeft.'}></AlertBox>;
        //     case 'hybride':
        //         return <AlertBox header={'Let op: u krijgt een hybride (geel) vignet.'} text={'Uit uw gegevens blijkt dat u recht hebt op een hybride (geel) vignet. Dat betekent dat uw boot een elektrische motor en een diesel- of benzinemotor heeft. Vaart u op het Amsterdamse binnenwater? Dan mag u alleen de elektrische motor gebruiken.'}></AlertBox>;
        //     case 'abonnement':
        //         return <AlertBox header={'Let op: u krijgt een abonnement (wit) vignet.'} text={'Uit uw gegevens blijkt dat u recht hebt op een abonnement (wit) vignet. Dat betekent dat uw boot in een jachthaven of buiten Amsterdam ligt. Met dit vignet mag u tussen 23.00 uur en 06.00 uur niet afmeren op het Amsterdamse binnenwater.'}></AlertBox>;
        //     case 'regulier':
        //         return <AlertBox header={'Let op: u krijgt een regulier (rood) vignet.'} text={'Uit uw gegevens blijkt dat u recht hebt op een regulier (rood) vignet. Dat betekent dat uw boot een diesel- of benzinemotor heeft. Met dit vignet mag u varen en afmeren op het Amsterdamse binnenwater. Vanaf 2025 mag u in het centrum van Amsterdam alleen nog maar uitstootvrij varen.'}></AlertBox>;
        // }
        return (
            <React.Fragment></React.Fragment>
        )
    }

    const getFileUploadDOM = () => {
        const isGreenVignet = vignet.vignet_type === 'Hybride' || vignet.vignet_type === 'Milieu';
        return (
            <React.Fragment>
                {
                    vignet.documents?.find((entry: string)=>{return entry === 'Ligplaatsbewijs'}) && !isGreenVignet ?
                        <div>
                            <h4>Voeg hier uw documenten toe</h4>
                            <div className={style.description}>
                                Ligt uw boot in een jachthaven? Upload de jachthavenfactuur. Op de factuur moet de naam en het adres van de jachthaven staan. En uw eigen adres moet op de factuur staan.
                                <br></br><br></br>
                                Ligt uw boot in van de gemeente gepacht water in Amsterdam? Upload een geldig erfpachtcontract.
                                <br></br><br></br>
                                Ligt uw boot buiten Amsterdam? Upload een foto van de ligplaats met de afgemeerde boot. Upload ook een kaart van de locatie.
                                <br></br><br></br>
                                {fileUploadText}
                            </div>
                            <UploadField removeFile={(index: number) => { removeFile(index, fileUploadMarina, setFileUploadMarina) }} validity={validity || fileUploadMarina.length > 0} onChange={handleOnChangeFileUploadMarina} files={fileUploadMarina}></UploadField>
                        </div>
                        : <React.Fragment></React.Fragment>
                }
                <br></br>
                {
                    parseInt(vignet.object_length) >= 10 ?
                        <div>
                            <h4>Voeg document ontheffing toe</h4>
                            <div className={style.description}>Is uw boot 10 meter of langer? Dan moet u hiervoor een ontheffing of vrijstelling hebben. Deze ontheffing moet u hier uploaden.</div>
                            <UploadField removeFile={(index: number) => { removeFile(index, fileUploadBoat, setFileUploadBoat) }} validity={validity || fileUploadBoat.length > 0} onChange={handleOnChangeFileUploadBoat} files={fileUploadBoat}></UploadField>
                        </div> :
                        <React.Fragment></React.Fragment>
                }
                <br></br>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <PageHeader logout={true} className={style.pageHeader}></PageHeader>
            <div id={style.container}>
                <FormStepper steps={steps} currentStep={2}></FormStepper>
                <CenteredForm locked={uploadingFiles || locked} onPrevious={staticMode ? undefined : handleOnPrevious} onNext={handleOnNext}>
                    <div id={style.header}>Wijzigen en betalen {vignet.vignet_number}</div>
                    <div id={style.subHeader}>Hieronder ziet u de gegevens van het vignet dat u wilt wijzigen.</div>
                    <KeyValuePair label={'Naam van uw boot'} value={vignet.object_name}></KeyValuePair>
                    <KeyValuePair label={'Vignetnummer'} value={vignet.vignet_number}></KeyValuePair>
                    <div className={style.pageSubHeader}>Gegevens van uw boot<div className={style.editButton} onClick={() => { setExpansion(0); setEditMode(true) }}>Wijzig</div></div>
                    <KeyValuePair editable={editMode ? handleOnChangeLength : undefined} label={'Lengte'} value={vignet.object_length}></KeyValuePair>
                    <KeyValuePair editable={editMode ? handleOnChangeWidth : undefined} label={'Breedte'} value={vignet.object_width}></KeyValuePair>
                    <KeyValuePair editOptions={driveTypeEditOptions} editable={editMode ? handleOnChangeDriveType : undefined} label={'Soort motor'} value={getDriveTypeName()}></KeyValuePair>
                    <KeyValuePair editOptions={marinaEditOptions} editable={editMode ? handleOnChangeMarina : undefined} label={'Ligt uw boot in een jachthaven, gepacht water of buiten Amsterdam?'} value={vignet.object_marina && parseInt(vignet.object_marina) > 0 ? 'Ja' : 'Nee'}></KeyValuePair>
                    {expansion > 0 ? <ChangeVignetPageCalculations vignetType={vignet.vignet_type_display_name} objectSurface={vignet.object_surface_area} totalCost={getVignetCost()}></ChangeVignetPageCalculations> : <React.Fragment></React.Fragment>}
                    {expansion > 0 ? getVignetTypeWarningDOM() : <React.Fragment></React.Fragment>}
                    {expansion > 0 ? getFileUploadDOM() : <React.Fragment></React.Fragment>}
                    {expansion > 1 ? <ChangeVignetPagePaymentTerms paymentMethod={paymentMethod} returnPaymentMethodID={returnPaymentMethodID} expansion={expansion} setExpansion={setExpansion} setEditMode={setEditMode} disabled={expansion !== 2} validity={validity} onChange={handleChangePaymentMethod} setPageError={setPageError} iban={iban || owner.account_number} ibanHolder={ibanHolder || owner.account_owner}></ChangeVignetPagePaymentTerms> : <React.Fragment></React.Fragment>}
                    {expansion > 2 ? <ChangeVignetPageSummary {...getSummary()}></ChangeVignetPageSummary> : <React.Fragment></React.Fragment>}
                    {
                        staticMode ? getPaymentStatusDOM() : <React.Fragment></React.Fragment>
                    }
                    {pageError ? <div id={style.pageError}>{pageError}</div> : <React.Fragment></React.Fragment>}
                </CenteredForm>
            </div>
        </React.Fragment>
    )
};

export default ChangeVignetPage;