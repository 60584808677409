import * as React from 'react';
import { useNavigate } from 'react-router';
import DataApi, { TCancelationType, TReason, TVignetType } from '../../api/DataApi';
import UserApi, { TOwner } from '../../api/UserApi';
import VignetApi, { TVignet } from '../../api/VignetApi';
import AgreementBox from '../../components/agreement-box/AgreementBox';
import AlertBox from '../../components/alert-box/AlertBox';
import CenteredForm from '../../components/centered-form/CenteredForm';
import DownloadSummaryButton from '../../components/download-summary/DownloadSummary';
import EditHeader from '../../components/edit-header/EditHeader';
import InlineRadioSelectionField from '../../components/inline-radio-selection-field/InlineRadioSelectionField';
import KeyValuePair from '../../components/key-value-pair/KeyValuePair';
import PageHeader from '../../components/page-header/PageHeader';
import UploadField from '../../components/upload-field/UploadField';
import FormStepper, { TStep } from '../form-stepper/FormStepper';

import style from './VignetOverviewPage.module.css';

enum ECancelationSubType {
    CANCELATION,
    CHANGE
}

const VignetOverviewPage = () => {

    const [vignet, setVignet] = React.useState<TVignet | undefined>(undefined);
    const [progress, setProgress] = React.useState<number>(0);

    const [actionType, setActionType] = React.useState<string | undefined>(undefined);
    const [cancelationType, setCancelationType] = React.useState<TReason | undefined>(undefined);
    const [cancelationSubType, setCancelationSubType] = React.useState<ECancelationSubType | undefined>(undefined);

    const [files, setFiles] = React.useState<any>([]);

    const [debug, setDebug] = React.useState<boolean>(true);
    const [debugOption, setDebugOption] = React.useState<string | undefined>(undefined);

    const [hasAgreedTruthfully, setHasAgreedTruthfully] = React.useState<boolean>(false);
    const [hasAgreedEndUser, setHasAgreedEndUser] = React.useState<boolean>(false);


    const [hasPressedNext, setHasPressedNext] = React.useState<boolean>(false);
    const [pageError, setPageError] = React.useState<string | undefined>(undefined);

    const [working, setWorking] = React.useState<boolean>(false);

    const [owner, setOwner] = React.useState<TOwner | undefined>(() => {
        UserApi.getOwner((json: any) => {
            setOwner(json);
        }, (error: Error) => {
            console.error(error);
        });
        return undefined;
    });

    const [cancelationTypes, setCancelationTypes] = React.useState<TCancelationType | undefined>(() => {
        DataApi.getCancelationTypes((cancelationTypes: TCancelationType) => {
            setCancelationTypes(cancelationTypes);
        }, (error: Error) => {
            console.error(error);
        })
        return undefined;
    })

    const [vignets, setVignets] = React.useState<TVignet[] | undefined>(() => {
        VignetApi.getVignets((json: any) => {
            setVignets(json.vignets);
        }, (error: Error) => {
            console.error(error);
        });
        return undefined;
    });

    const navigate = useNavigate();

    if (!vignets || !owner) {
        return (
            <div>Loading..</div>
        )
    }

    const steps: TStep[] = [
        { text: 'Uw gegevens' },
        { text: 'Vignetoverzicht' },
        { text: vignet?.vignet_number || 'Uw vignet' },
        { text: 'Samenvatting' },
        { text: 'Bedankt' }
    ]

    const handleOnClickActionButton = (vignet: TVignet) => {
        setVignet(vignet);
        setProgress(progress + 1);
    }

    const handleOnChangeActionType = (e: any) => {
        setHasPressedNext(false);
        setPageError(undefined);
        setActionType(e.target.value);
    }

    const handleOnChangeCancelationType = (e: any) => {
        let cancelationSubType = undefined;
        let cancelationType = cancelationTypes?.cancellation.find((reason: TReason) => {
            return reason.ReasonID === e.target.value;
        })
        if (!cancelationType) {
            cancelationSubType = ECancelationSubType.CHANGE;
            cancelationType = cancelationTypes?.change.find((reason: TReason) => {
                return reason.ReasonID === e.target.value;
            })
        }else{
            cancelationSubType = ECancelationSubType.CANCELATION
        }
        setHasPressedNext(false);
        setPageError(undefined);
        setCancelationType(cancelationType);
        setCancelationSubType(cancelationSubType);
    }

    const handleOnFileChange = (e: any) => {
        if (e.target.files && e.target.files[0]) {
            const files = [e.target.files[0]];
            setPageError(undefined);
            setFiles(files);
            return;
        }
        setFiles([]);
    }

    const processTextDocument = (textDocument: string) => {
        const links = textDocument.split('[/link]');

        return links.map((link: string, index: number) => {
            if (!link.includes('[link url=')) {
                return link;
            }
            const parts = link.split('[link url=');

            const rawLink = parts[1].split(']');
            return [parts[0], <a target="_blank" key={'parsed_link_' + index} href={rawLink[0]}>{rawLink[1]}</a>]
        });
    }

    const getCancelationDOM = () => {
        if (cancelationType) {
            if (cancelationType.RestitutionPossible) {
                return (
                    <React.Fragment>
                        <AlertBox text={cancelationType.ExplanationText}></AlertBox>
                        <div className={style.pageHeader}>Document</div>
                        <div>
                            {processTextDocument(cancelationType.TextDocument)}
                        </div>
                        <UploadField singleFileText={true} removeFile={(index: number) => { setFiles([]) }} validity={!hasPressedNext || files.length > 0} onChange={handleOnFileChange} files={files}></UploadField>
                        <div>Ieder bestand mag niet groter zijn dan 200MB en moet een JPG-, JPEG- of pdf-bestand zijn.</div>
                        {pageError ? <div id={style.pageError}>{pageError}</div> : <React.Fragment></React.Fragment>}
                    </React.Fragment>
                )
            }
            return (
                <AlertBox text={cancelationType.ExplanationText}></AlertBox>
            )
        }
        return (
            <React.Fragment></React.Fragment>
        )
    }

    const getExtendedChangeDOM = () => {
        if (cancelationType) {
            if (cancelationType.RestitutionPossible) {
                return (
                    <React.Fragment>
                        <AlertBox text={cancelationType.ExplanationText}></AlertBox>
                        <div className={style.pageHeader}>Document</div>
                        <div>
                            {cancelationType.TextDocument}
                        </div>
                        <UploadField singleFileText={true} removeFile={(index: number) => { setFiles([]) }} validity={!hasPressedNext || files.length > 0} onChange={handleOnFileChange} files={files}></UploadField>
                        <div>Ieder bestand mag niet groter zijn dan 200MB en moet een JPG-, JPEG- of pdf-bestand zijn.</div>
                        {pageError ? <div id={style.pageError}>{pageError}</div> : <React.Fragment></React.Fragment>}
                    </React.Fragment>
                )
            }
            return (
                <AlertBox text={cancelationType.ExplanationText}></AlertBox>
            )
        }
        return (
            <React.Fragment></React.Fragment>
        )
    }

    const getChangeDOM = () => {
        return (
            <React.Fragment>
                <div className={style.pageHeader}>Type wijziging</div>
                <select defaultValue={cancelationType?.ReasonID} className={style.option} onChange={handleOnChangeCancelationType}>
                    <option></option>
                    {
                        cancelationTypes?.change?.map((cancelationType: TReason, index: number) => {
                            return (
                                <option key={'change_reason_option_' + index} value={cancelationType.ReasonID}>{cancelationType.Name}</option>
                            )
                        })
                    }
                </select>
                {getExtendedChangeDOM()}
            </React.Fragment>
        )
    }

    const getCancelDOM = () => {
        return (
            <React.Fragment>
                <div className={style.pageHeader}>Reden van opzegging</div>
                <select defaultValue={cancelationType?.ReasonID} className={style.option} onChange={handleOnChangeCancelationType}>
                    <option></option>
                    {
                        cancelationTypes?.cancellation?.map((cancelationType: TReason, index: number) => {
                            return (
                                <option key={'cancelation_reason_option_' + index} value={cancelationType.ReasonID}>{cancelationType.Name}</option>
                            )
                        })
                    }
                </select>
                {getCancelationDOM()}
            </React.Fragment>
        )
    }

    const handleOnPrevious = () => {
        setPageError(undefined);
        setHasPressedNext(false);
        if (progress < 0) {
            navigate('/');
        }
        setProgress(progress - 1);
    }

    const handleOnNext = () => {
        setHasPressedNext(true);
        if(progress === 1) {
            if(cancelationType?.RestitutionPossible) {
                if(!files || files.length <= 0) {
                    setPageError('Voeg een bestand toe.')
                    return;
                }
            }
        }
        if (actionType === 'change') {
            setProgress(progress + 1);
        }
        if (actionType === 'cancel') {
            setProgress(progress + 1);
        }
        setHasPressedNext(false);
    }

    const handleCancelation = () => {
        if(working){
            return;
        }
        setWorking(true);

        setHasPressedNext(true);

        if (!vignet || !vignet.vignet_id || !cancelationType) {
            setWorking(false);
            return;
        }

        if(progress === 2) {
            if(!hasAgreedEndUser || !hasAgreedTruthfully) {
                setPageError('Ga akkoord met het bovenstaande om verder te gaan.');
                setWorking(false);
                return;
            }
        }

        setHasPressedNext(false);

        if (cancelationType?.RestitutionPossible) {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                const data: string = (fileReader.result as string).split(';base64,', 2)[1];
                VignetApi.sendRestitutionRequest(vignet.vignet_id, cancelationType.ReasonID, data, () => {
                    setProgress(progress + 1);
                    setWorking(false);
                }, () => {
                    console.log('failed');
                    setWorking(false);
                });
            }
            fileReader.readAsDataURL(files[0]);
        } else {
            VignetApi.sendRestitutionRequest(vignet.vignet_id, cancelationType.ReasonID, '', () => {
                setProgress(progress + 1);
                setWorking(false);
            }, () => {
                console.log('failed');
                setWorking(false);
            });
        }
    }

    if (progress === 3) {
        const getThankYouHeader = () => {
            if (cancelationSubType === ECancelationSubType.CHANGE) {
                return (<div id={style.subHeader}>Hartelijk dank voor uw verzoek tot wijziging. U krijgt per e-mail een bevestiging. Binnen 5 werkdagen ontvangt u
                    ons besluit.</div>)
            }
            return (<div id={style.subHeader}>
                Hartelijk dank voor uw verzoek tot opzegging. U krijgt per e-mail een bevestiging. Binnen 5 werkdagen ontvangt u ons
                besluit.</div>)
        }

        const getThankYouFooter = () => {
            return (<div id={style.subHeader}>Kijk op <a href='https://amsterdam.nl/varen'>amsterdam.nl/varen</a>.
                Of stuur een e-mail naar <a href="mailto: vaarvignet@amsterdam.nl">vaarvignet@amsterdam.nl</a>
                . Noteer in uw e-mail altijd het
                vignetnummer</div>)
        }

        const handleOnClick = () => {
            console.log('getting summary');
            DataApi.getRestitutionSummary(vignet?.vignet_id || '', (json: any)=>{
                window.open(json.summary_link);
            }, (error: Error)=>{
                console.error(error);
            })
        }

        return (
            <React.Fragment>
                <PageHeader logout={true} className={style.pageHeader}></PageHeader>
                <div id={style.container}>
                    <CenteredForm onPrevious={()=>{navigate('/redirect/vignet-overview')}} onPreviousText="< Terug naar vignetoverzicht">
                        <div id={style.header}>Bedankt</div>
                        {getThankYouHeader()}
                        <div className={style.pageSubHeader}>Samenvatting</div>
                        <div>

                        </div>
                        <DownloadSummaryButton onClick={handleOnClick}></DownloadSummaryButton>
                        <div className={style.pageSubHeader}>Vragen?</div>
                        {getThankYouFooter()}
                    </CenteredForm>
                </div>
            </React.Fragment>
        )
    }

    if (progress === 2) {
        if (!vignet) {
            setProgress(0);
            return (
                <div>Loading..</div>
            )
        }

        return (
            <React.Fragment>
                <PageHeader logout={true} className={style.pageHeader}></PageHeader>
                <div id={style.container}>
                    <FormStepper steps={steps} currentStep={3}></FormStepper>
                    <CenteredForm onPrevious={() => { handleOnPrevious() }} locked={working} onNext={() => { handleCancelation() }}>
                        <div id={style.header}>Samenvatting</div>
                        <div id={style.subHeader}>Hieronder staan uw gegevens van het {(cancelationSubType === ECancelationSubType.CANCELATION ? 'opzeggen' : 'wijzigen')} van uw vignet.</div>
                        <KeyValuePair label={'BSN'} value={owner.bsn}></KeyValuePair>
                        <KeyValuePair label={'Uw voorletters'} value={owner.initials}></KeyValuePair>
                        <KeyValuePair label={'Tussenvoegsel'} value={owner.prefix}></KeyValuePair>
                        <KeyValuePair label={'Achternaam'} value={owner.lastname}></KeyValuePair>
                        <KeyValuePair label={'Straat'} value={owner.address}></KeyValuePair>
                        <KeyValuePair label={'Huisnummer'} value={owner.number} inline={true}></KeyValuePair>
                        <KeyValuePair label={'Toevoeging'} value={owner.addition} inline={true}></KeyValuePair>
                        <KeyValuePair label={'Postcode'} value={owner.postcode} inline={true}></KeyValuePair>
                        <KeyValuePair label={'Woonplaats'} value={owner.city} inline={true}></KeyValuePair>
                        <KeyValuePair label={'Telefoonnummer'} value={owner.mobilephone}></KeyValuePair>
                        <KeyValuePair label={'E-mailadres'} value={owner.email}></KeyValuePair>
                        <KeyValuePair label={'Naam boot'} value={vignet.object_name}></KeyValuePair>
                        <KeyValuePair label={'Vignetnummer'} value={vignet.vignet_number}></KeyValuePair>
                        <KeyValuePair label={'Reden van ' + (cancelationSubType === ECancelationSubType.CANCELATION ? 'opzegging' : 'wijzigen')} value={cancelationType?.Name || ''}></KeyValuePair>
                        <br></br>

                        <AgreementBox defaultChecked={hasAgreedTruthfully} validity={!hasPressedNext || hasAgreedTruthfully} text={'Ik heb alle gegevens correct en volledig ingevuld. Ik begrijp dat een aanvraag via internet dezelfde juridische status heeft als een aanvraag met een geschreven geldige handtekening.'} onChange={(checked: boolean) => { setHasAgreedTruthfully(checked) }}></AgreementBox>
                        <AgreementBox defaultChecked={hasAgreedEndUser} validity={!hasPressedNext || hasAgreedEndUser} text={'Ik ben op de hoogte van de regels die op het water van Amsterdam gelden, zoals ze staan beschreven in de Binnenhavengeldverordening Pleziervaart en de Verordening op het binnenwater 2010. Kijk op '} onChange={(checked: boolean) => { setHasAgreedEndUser(checked) }}><a href='https://www.amsterdam.nl/parkeren-verkeer/varen-amsterdam/re-geling-verordeningen-water/'>"https://www.amsterdam.nl/parkeren-verkeer/varen-amsterdam/re-geling-verordeningen-water/" </a><div>voor meer informatie.</div></AgreementBox>

                        {pageError ? <div id={style.pageError}>{pageError}</div> : <React.Fragment></React.Fragment>}
                    </CenteredForm>
                </div>
            </React.Fragment>
        )
    }

    if (progress === 1) {
        if (!vignet) {
            setProgress(0);
            return (
                <div>Loading..</div>
            )
        }
        return (
            <React.Fragment>
                <PageHeader logout={true} className={style.pageHeader}></PageHeader>
                <div id={style.container}>
                    <FormStepper steps={steps} currentStep={2}></FormStepper>
                    <CenteredForm onPrevious={handleOnPrevious} onNext={cancelationType?.RequestPossible ? handleOnNext : undefined}>
                        <div id={style.header}>Wijzigen of opzeggen {vignet.vignet_number}</div>
                        <div id={style.subHeader}>Hieronder ziet u het vignet dat u wilt wijzigen of opzeggen.</div>
                        <KeyValuePair label={'Naam van uw boot'} value={vignet.object_name}></KeyValuePair>
                        <KeyValuePair label={'Vignetnummer'} value={vignet.vignet_number}></KeyValuePair>
                        <div className={style.pageSubHeader}>Vignet wijzigen of opzeggen</div>
                        <div className={style.selectTypeContainer}>
                            <input type={'radio'} defaultChecked={cancelationSubType === ECancelationSubType.CHANGE} name={'actionType'} value={'change'} onChange={handleOnChangeActionType}></input>
                            <div>Wijzigen</div>
                        </div>
                        <div className={style.selectTypeContainer}>
                            <input type={'radio'} defaultChecked={cancelationSubType === ECancelationSubType.CANCELATION} name={'actionType'} value={'cancel'} onChange={handleOnChangeActionType}></input>
                            <div>Opzeggen</div>
                        </div>
                        {actionType === 'change' ? getChangeDOM() : <React.Fragment></React.Fragment>}
                        {actionType === 'cancel' ? getCancelDOM() : <React.Fragment></React.Fragment>}
                    </CenteredForm>
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <PageHeader logout={true} className={style.pageHeader}></PageHeader>
            <div id={style.container}>
                <FormStepper steps={steps} currentStep={1}></FormStepper>
                <CenteredForm onPrevious={() => { navigate('/personal-info') }} onNext={debug ? undefined : debugOption ? () => { if (debugOption) { console.log(debugOption); navigate(debugOption) } } : undefined}>
                    <div id={style.header}>Vignetoverzicht</div>
                    <div id={style.subHeader}>
                        U kunt per vignet aangeven of u wilt wijzigen of opzeggen. Hebt u meerdere vignetten? Doorloop de stappen dan per vignet.<br></br><br></br>
                        U ziet hieronder alleen uw huidige BHG-vignet of vignetten. Hebt u een Doorvaartvignet? En wilt u hiervan gegevens wijzigen? Stuur een e-mail naar <a href="mailto: vaarvignet@amsterdam.nl">vaarvignet@amsterdam.nl</a>.
                        Noteer in uw e-mail uw vignetnummer en wat u wilt wijzigen.<br></br>
                    </div>
                    <div></div>
                    <div id={style.vignetTable}>
                        <div id={style.vignetTableHeader}>
                            <div className={style.vignetColumn}>Naam van uw boot</div>
                            <div className={style.vignetColumn}>Vignetnummer</div>
                            <div className={style.vignetColumn}></div>
                        </div>
                        <div className={style.vignetTableSeparator}></div>
                        {
                            vignets.map((vignet: TVignet, index: number) => {
                                return (
                                    <div key={'vignet_' + index} className={style.vignetTableRow}>
                                        <div className={style.vignetTableRowItem}>{vignet.object_name}</div>
                                        <div className={style.vignetTableRowItem}>{vignet.vignet_number}</div>
                                        <div className={style.vignetTableRowItemCentered}>
                                            {vignet.vignet_status !== 'Extended' && vignet.show_blue_btn === 1 ? <div id={style.actionButton} onClick={() => { handleOnClickActionButton(vignet) }}>Wijzigen of opzeggen</div> : <React.Fragment></React.Fragment>}
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className={style.vignetTableSeparator}></div>
                    </div>
                </CenteredForm>
                {/* <div>
                    <input type={'checkbox'} onChange={(e: any)=>{setDebug(e.target.checked)}} style={{accentColor: 'red'}}></input>
                    Debug mode: after 1 feb
                </div> */}
            </div>
        </React.Fragment>
    )
};

export default VignetOverviewPage;