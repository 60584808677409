import process from 'process';

// const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const development = false;

console.log('Development:', development);

type TApi = {
    baseUrl: string
}

type TGoogleMaps = {
    apiKey: string
}

type TConfig = {
    version: string,
    development: boolean,
    api: TApi,
    googleMaps: TGoogleMaps,
    transitRedirectUrl: string
}

const Config: TConfig = {
    version: 'Mon, 22 Jul 2024 16:31:26 GMT',
    development: development,
    transitRedirectUrl: 'https://mijnvignet.amsterdam.digitaalvignet.nl/',
    api: {
        baseUrl: development ? 'https://acc.backoffice.digitaalvignet.nl' : 'https://backoffice.digitaalvignet.nl'
    },
    googleMaps: {
        apiKey: development ? 'AIzaSyAgHk7F-ThLbXGzPDJO9oKt6o70P-y0oho' : 'AIzaSyBgELrqcqIjqs8Swp2oDJ5c9BdBeQuAxXU'
    }
}

export default Config;
