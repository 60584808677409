import * as React from 'react';

import style from './ChangeVignetPagePaymentDetails.module.css';

type TChangeVignetPagePaymentDetails = {
    onChangeIban: Function,
    onChangeIbanHolder: Function,
    disabled: boolean,
    validity: boolean,
    iban?: string,
    ibanHolder?: string,
}

const ChangeVignetPagePaymentDetails = (props: TChangeVignetPagePaymentDetails) => {
    const [iban, setIban] = React.useState<string>(props.iban || '');
    const [ibanHolder, setIbanHolder] = React.useState<string>(props.ibanHolder || '');

    const handleOnChangeIban = (e: any) => {
        setIban(e.target.value);
        props.onChangeIban(e);
    }

    const handleOnChangeIbanHolder = (e: any) => {
        setIbanHolder(e.target.value);
        props.onChangeIbanHolder(e);
    }

    return (
        <React.Fragment>
            <div className={style.label}>Rekeningnummer</div>
            <input disabled={props.disabled} value={iban} className={`${style.input} ${!props.validity && !iban ? style.invalid : style.valid}`} name={'iban'} type={'text'} onChange={(e: any)=>{handleOnChangeIban(e)}}></input>
            <div className={style.label}>Naam rekeninghouder</div>
            <input disabled={props.disabled} value={ibanHolder} className={`${style.input} ${!props.validity && !ibanHolder ? style.invalid : style.valid}`} name={'ibanHolder'} type={'text'} onChange={(e: any)=>{handleOnChangeIbanHolder(e)}}></input>
        </React.Fragment>
    )
};

export default ChangeVignetPagePaymentDetails;