import * as React from 'react';
import DataApi, { TCountry } from '../../api/DataApi';

import style from './CountrySelectionField.module.css';

type TCountrySelectionField = {
    text: string;
    onChange: Function;
    valid?: boolean;
    defaultValue: string;
    disabled?: boolean;
}

const CountrySelectionField = (props: TCountrySelectionField) => {
    const [initial, setInitial] = React.useState<boolean>(true);
    const [countries, setCountries] = React.useState<TCountry[] | undefined>(() => {
        DataApi.getCountries((countries: TCountry[]) => {
            setCountries(countries);
        }, (error: Error) => {
            console.error(error);
        })
        return undefined;
    });

    const handleOnChange = (e: any) => {
        if (!countries) {
            return;
        }
        const countryIndex = countries?.findIndex((country: TCountry) => {
            return country.id === e.target.value;
        })
        props.onChange(countries[countryIndex].id, countryIndex, countries[countryIndex].name)
    }

    if (initial && countries) {
        if (props.defaultValue) {
            const countryIndex = countries?.findIndex((country: TCountry) => {
                return country.id === props.defaultValue;
            })
            props.onChange(countries[countryIndex].id, countryIndex, countries[countryIndex].name);
        }
        setInitial(false);
    }

    return (
        <div id={style.container}>
            <div id={style.label}>{props.text}</div>
            <select id={style.input} className={props.valid ? style.valid : style.invalid} onChange={handleOnChange} value={props.defaultValue} disabled={props.disabled}>
                <option></option>
                {
                    countries?.map((country: TCountry, index: number) => {
                        return (
                            <option key={'country_selection_option_' + index} value={country.id}>{country.name}</option>
                        )
                    })
                }
            </select>
        </div>
    )

}

export default CountrySelectionField;