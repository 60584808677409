import Config from "../config/Config";
import Util from "../util/Util";

type TOwner = {
    addition: string,
    address: string,
    bsn: string,
    city: string,
    email: string,
    initials: string,
    lastname: string,
    mobilephone: string,
    number: string,
    postcode: string,
    prefix: string,
    account_number?: string,
    account_owner?: string
}

const getOwner = (onSuccess: Function, onFailure: Function) => {
    const accessToken = window.localStorage.getItem('access_token');
    if(!accessToken){
        onFailure(new Error('No access token'));
    }
    fetch(Config.api.baseUrl + '/api-extend/get-owner/nl', {
        method: 'GET',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        })
    }).then(async (response: Response) => {
        const json = await response.json();
        if(json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
};

const updateOwner = (address: string, houseNumber: string, addition: string, zipcode: string, city: string, email: string, phoneNumber: string, mobilePhone: string, onSuccess: Function, onFailure: Function) => {
    const accessToken = window.localStorage.getItem('access_token');
    if(!accessToken){
        onFailure(new Error('No access token'));
    }
    fetch(Config.api.baseUrl + '/api-extend/update-owner/nl', {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        }),
        body: JSON.stringify({
            address: address,
            number: houseNumber,
            postcode: zipcode,
            city: city,
            email: email,
            mobilephone: mobilePhone,
            phone: phoneNumber,
            addition: addition
        })
    }).then(async (response: Response) => {
        const json = await response.json();
        if(json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
};

const login = (email: string, password: string, onSuccess: Function, onFailure: Function) => {
    fetch(Config.api.baseUrl + '/api-extend/login/nl', {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({email: email, password: password})
    }).then(async (response: Response) => {
        const json = await response.json();
        if(json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const sendForgotPassword = (email: string, onSuccess: Function, onFailure: Function) => {
    fetch(Config.api.baseUrl + '/api-extend/reset-password/nl', {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({base_url: Util.getPageUrl(), email: email})
    }).then(async (response: Response) => {
        const json = await response.json();
        if(json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const changePassword = (token: string, password: string, onSuccess: Function, onFailure: Function) => {
    fetch(Config.api.baseUrl + '/api-extend/reset-password/nl', {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
            token: token,
            password: password
        })
    }).then(async (response: Response) => {
        const json = await response.json();
        if(json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const UserApi = {
    login: login,
    sendForgotPassword: sendForgotPassword,
    changePassword: changePassword,
    getOwner: getOwner,
    updateOwner: updateOwner
}

export default UserApi;
export type { TOwner };