import * as React from 'react';
import { useNavigate, useParams } from 'react-router';
import UserApi from '../../api/UserApi';

import style from './ResetPassword.module.css';

type TResetPassword = {
    className?: string
}

const ResetPassword = (props: TResetPassword) => {
    const [done, setDone] = React.useState<boolean>(false);
    const [email, setEmail] = React.useState<string | undefined>(undefined);
    const [error, setError] = React.useState<string | undefined>(undefined);
    
    const [password, setPassword] = React.useState<string | undefined>(undefined);
    const [repeatPassword, setRepeatPassword] = React.useState<string | undefined>(undefined);

    const navigate = useNavigate();

    const params = useParams();

    const handleOnChange = (e: any) => {
        setEmail(e.target.value.trim());
        if(e.target.value.length <= 0) {
            setError('Het veld e-mailadres is niet ingevuld');
            return;
        }
        if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value)) {
            setError('Het e-mailadres in het veld hierboven is onjuist ingevuld');
            return
        }
        setError(undefined);
    };

    const handleOnClick = () => {
        if(!email || email.length <= 0) {
            setError('Het veld e-mailadres is niet ingevuld');
            return;
        }
        if(error){
            return;
        }
        UserApi.sendForgotPassword(email as string, (json: any)=>{
            setDone(true);
        }, (error: Error)=>{
            console.error(error);
        })
    };

    const eightCharacterCheck = () => {
        if(!password){return false};
        return password.length >= 8;
    }

    const maxLengthCheck = () => {
        if(!password){return false};
        return password.length <= 24;
    }

    const numberCheck = () => {
        if(!password){return false};
        return /\d\D*\d/.test(password)
    }

    const capitalizedCheck = () => {
        if(!password){return false};
        return password !== password.toLowerCase();
    }

    const specialCharacterCheck = () => {
        if(!password){return false};
        return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)
    }

    const sendChangePasswordRequest = () => {
        if(password !== repeatPassword) {
            setError('Wachtwoorden komen niet overeen.');
            return;
        }

        if(!eightCharacterCheck() || !numberCheck() || !capitalizedCheck() || !specialCharacterCheck() || !maxLengthCheck()) {
            setError('Uw wachtwoord voldoet niet aan de eisen.');
            return;
        }

        UserApi.changePassword(params.token || '', password || '', () => {
            setDone(true);
        }, (error: Error)=>{
            setError(error.message);
        })
    }

    if(params.token) {

        if (done) {
            return (
                <div id={style.container} className={props.className}>
                    <div id={style.header}>Wachtwoord herstellen</div>
                    <div id={style.description}>Bedankt! U wachtwoord is veranderd.</div>
                    <div id={style.forgotPasswordContainer}>
                        <div id={style.forgotPassword} onClick={() => { navigate('/') }}>Terug naar inloggen</div>
                    </div>
                </div>
            )
        }

        return (
            <div id={style.container} className={props.className}>
                <div id={style.header}>Wachtwoord herstellen</div>
                <div id={style.description}>Vul een nieuw wachtwoord in.</div>
                Uw wachtwoord moet aan de volgende eisen voldoen:
                <ul>
                    <li className={eightCharacterCheck() ? style.validPasswordRule : style.invalidPasswordRule}>minimaal 8 tekens</li>
                    <li className={numberCheck() ? style.validPasswordRule : style.invalidPasswordRule}>minimaal 2 cijfers</li>
                    <li className={capitalizedCheck() ? style.validPasswordRule : style.invalidPasswordRule}>minimaal 1 hoofdletter</li>
                    <li className={specialCharacterCheck() ? style.validPasswordRule : style.invalidPasswordRule}>minimaal 1 speciaal leesteken</li>
                    <li className={maxLengthCheck() ? style.validPasswordRule : style.invalidPasswordRule}>maximaal 24 tekens</li>
                </ul>
                <label className={style.label}>Wachtwoord</label>
                <input className={`${style.input} ${error ? style.invalid : ''}`} type='password' name='password' onChange={(e: any)=>{setPassword(e.target.value)}}></input>
                <label className={style.label}>Herhaal wachtwoord</label>
                <input className={`${style.input} ${error ? style.invalid : ''}`} type='password' name='repeat-password' onChange={(e: any)=>{setRepeatPassword(e.target.value)}}></input>
                {error ? <div className={style.error}>{error}</div> : ''}
                <a href='/'>{'< Vorige'}</a><button id={style.submitButton} onClick={sendChangePasswordRequest}>Volgende</button>
            </div>
        )
    }

    if (done) {
        return (
            <div id={style.container} className={props.className}>
                <div id={style.header}>Wachtwoord vergeten</div>
                <div id={style.description}>Bedankt! U ontvangt binnen enkele minuten een e-mail waarmee u een nieuw wachtwoord kunt aanmaken.</div>
                <div id={style.forgotPasswordContainer}>
                    <div id={style.forgotPassword} onClick={() => { navigate('/') }}>Terug naar inloggen</div>
                </div>
            </div>
        )
    }

    return (
        <div id={style.container} className={props.className}>
            <div id={style.header}>Wachtwoord vergeten</div>
            <div id={style.description}>Bent u uw wachtwoord vergeten? Vul dan hieronder uw e-mailadres in. U krijgt binnen enkele minuten een e-mail waarmee u een nieuw wachtwoord kunt aanmaken.</div>
            <label className={style.label}>E-mailadres</label>
            <input className={`${style.input} ${error ? style.invalid : ''}`} type='email' name='email' onChange={handleOnChange}></input>
            {error ? <div className={style.error}>{error}</div> : ''}
            <a href='/'>{'< Vorige'}</a><button id={style.submitButton} onClick={handleOnClick}>Versturen</button>
        </div>
    )
};

export default ResetPassword;