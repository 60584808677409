import * as React from 'react';

import style from './ButtonPrevious.module.css';

type Props = {
    children?: any,
    onClick: Function
}

const ButtonPrevious = (props: Props) => { 
    return <div className={style.buttonBase} onClick={(e)=>{props.onClick()}}>{props.children}</div>
}

export default ButtonPrevious;