import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import LoginPage from './containers/login-page/LoginPage';
import ResetPasswordPage from './containers/reset-password-page/ResetPasswordPage';
import PersonalInfoPage from './containers/personal-info-page/PersonalInfoPage';
import VignetOverviewPage from './containers/vignet-overview-page/VignetOverviewPage';
import ChangeVignetPage from './containers/change-vignet-page/ChangeVignetPage';
import CancelVignetPage from './containers/cancel-vignet-page/CancelVignetPage';
import MooringPage from './containers/mooring-page/MooringPage';
import MooringDonePage from './containers/mooring-done-page/MooringDonePage';
import ChangeVignetDonePage from './containers/change-vignet-done-page/ChangeVignetDonePage';
import CancelVignetDonePage from './containers/cancel-vignet-done-page/CancelVignetDonePage';
import ActivateVignetPage from './containers/activate-vignet-page/ActivateVignetPage';
import Redirector from './components/redirector/Redirector';
import ThreeStrikesRegistrationPage from './containers/three-strikes-registration-page/ThreeStrikesRegistrationPage';
import ResendSmsPage from './containers/resend-sms-page/ResendSmsPage';
import React from 'react';
import Config from './config/Config';
import ExternalRedirect from './components/external-redirect';

function App() {
    const demoModeKey: boolean = window.localStorage.getItem('demo_mode') ? JSON.parse(window.localStorage.getItem('demo_mode') || 'false') : false;
    const [demoMode, setDemoMode] = React.useState<boolean>(Config.development && !demoModeKey);

    return (
        <div className="App">
            { demoMode ?
                <div id={'developmentWarningHeader'} onContextMenu={(e: any) => {e.preventDefault(); window.localStorage.setItem('demo_mode', 'true'); setDemoMode(false);}}>
                    <div>Development environment<a href={Config.api.baseUrl} target='_blank'></a></div>
                    <div>{Config.version}</div>
                </div> : undefined
            }
            <BrowserRouter>
                <Routes>
                    <Route path='' element={<LoginPage></LoginPage>}></Route>
                    <Route path='/reset-password' element={<ResetPasswordPage></ResetPasswordPage>}></Route>
                    <Route path='/reset-password/reset/:token' element={<ResetPasswordPage></ResetPasswordPage>}></Route>
                    <Route path='/personal-info' element={<PersonalInfoPage></PersonalInfoPage>}></Route>
                    <Route path='/vignet-overview' element={<VignetOverviewPage></VignetOverviewPage>}></Route>
                    <Route path='/change-vignet' element={<Navigate to={'/vignet-overview'}></Navigate>}></Route>
                    <Route path='/change-vignet/:vignetID' element={<ChangeVignetPage></ChangeVignetPage>}></Route>
                    <Route path='/change-vignet/:data/:status' element={<ChangeVignetPage></ChangeVignetPage>}></Route>
                    <Route path='/change-vignet/:vignetID/done' element={<ChangeVignetDonePage></ChangeVignetDonePage>}></Route>
                    <Route path='/cancel-vignet' element={<Navigate to={'/vignet-overview'}></Navigate>}></Route>
                    <Route path='/cancel-vignet/:vignetID' element={<CancelVignetPage></CancelVignetPage>}></Route>
                    <Route path='/cancel-vignet/:vignetID/done' element={<CancelVignetDonePage></CancelVignetDonePage>}></Route>
                    
                    <Route path='/transit-vignet' element={<ExternalRedirect to={Config.transitRedirectUrl}/>}/>
                    <Route path='/transit-vignet/:data/:status' element={<ExternalRedirect to={Config.transitRedirectUrl}/>}/>
                    <Route path='/transit-vignet/done' element={<ExternalRedirect to={Config.transitRedirectUrl}/>}/>
                    {/* <Route path='/transit-vignet' element={<TransitPage></TransitPage>}></Route>
                    <Route path='/transit-vignet/:data/:status' element={<TransitPage></TransitPage>}></Route>
                    <Route path='/transit-vignet/done' element={<PassageDonePage></PassageDonePage>}></Route> */}
                    <Route path='/mooring-vignet' element={<MooringPage></MooringPage>}></Route>
                    <Route path='/mooring-vignet/:data/:status' element={<MooringPage></MooringPage>}></Route>
                    <Route path='/mooring-vignet/done' element={<MooringDonePage></MooringDonePage>}></Route>
                    <Route path='/activate-vignet' element={<ActivateVignetPage></ActivateVignetPage>}></Route>
                    <Route path='/registration' element={<ThreeStrikesRegistrationPage></ThreeStrikesRegistrationPage>}></Route>
                    <Route path='/registration/:data/:status' element={<ThreeStrikesRegistrationPage></ThreeStrikesRegistrationPage>}></Route>
                    <Route path='/redirect/:url' element={<Redirector></Redirector>}></Route>
                    <Route path='/resend-sms' element={<ResendSmsPage></ResendSmsPage>}></Route>
                    <Route path='*' element={
                        <React.Fragment>
                            <div>404 Not found</div>
                            <div>{window.location.href}</div>
                            <a href='/'>Home</a>
                        </React.Fragment>
                    }>
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
