import * as React from 'react';
import PageHeader from '../../components/page-header/PageHeader';
import Login from '../../components/login/Login';

import style from './LoginPage.module.css';

import process from 'process';

const LoginPage = () => {
    return (
        <div id={style.container}>
            <PageHeader className={style.pageHeader}></PageHeader>
            <img src={'/login-page-banner-upscaled-2.jpg'} id={style.banner}></img>
            <Login></Login>
        </div>
    )
};

export default LoginPage;