import Config from "../config/Config";
import Util from "../util/Util";
import { TPaymentType } from "./DataApi";

type TVignet = {
    object_drive_type_id: string,
    object_length: string,
    object_marina: string,
    object_name: string,
    object_surface_area: string,
    object_width: string,
    vignet_cost: string,
    vignet_id: string,
    vignet_number: string,
    vignet_type: string,
    vignet_type_display_name: string,
    show_blue_btn: number,
    vignet_status: string,
    documents?: string[]
}

type TCheckVignetField = {
    value: string,
    isEditable: boolean
}

type TCheckVignetFields = {
    [key: string]: TCheckVignetField
}

type TCheckVignet = {
    fields: TCheckVignetFields,
    access_token: string,
    vignet_type_id: string,
    photos_count: number,
    photos_required: boolean,
}

const getVignet = (vignetID: string, onSuccess: Function, onFailure: Function) => {
    const accessToken = window.localStorage.getItem('access_token');
    if (!accessToken) {
        onFailure(new Error('No access token'));
    }
    
    fetch(Config.api.baseUrl + '/api-extend/get-vignet/nl', {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        }),
        body: JSON.stringify({ vignet_id: vignetID })
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const updateVignet = (vignetID: string, objectLength: string, objectWidth: string, objectMarina: string, objectDriveTypeID: string, onSuccess: Function, onFailure: Function) => {
    const accessToken = window.localStorage.getItem('access_token');
    if (!accessToken) {
        onFailure(new Error('No access token'));
    }
    fetch(Config.api.baseUrl + '/api-extend/update-vignet', {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        }),
        body: JSON.stringify({
            vignet_id: vignetID,
            object_length: objectLength,
            object_width: objectWidth,
            object_marina: objectMarina,
            object_drive_type_id: objectDriveTypeID
        })
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
};

const getVignets = (onSuccess: Function, onFailure: Function) => {
    const accessToken = window.localStorage.getItem('access_token');
    if (!accessToken) {
        onFailure(new Error('No access token'));
    }
    fetch(Config.api.baseUrl + '/api-extend/get-vignets/nl', {
        method: 'GET',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        })
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const cancelVignet = (vignetID: string, reason: string, onSuccess: Function, onFailure: Function) => {
    const accessToken = window.localStorage.getItem('access_token');
    if (!accessToken) {
        onFailure(new Error('No access token'));
    }
    fetch(Config.api.baseUrl + '/api-extend/cancel-vignet', {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        }),
        body: JSON.stringify({ vignet_id: vignetID, reason: reason })
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
};

const extendVignet = (vignet: TVignet, paymentMethod: TPaymentType, iban: string | undefined, ibanHolder: string | undefined, onSuccess: Function, onFailure: Function) => {
    const accessToken = window.localStorage.getItem('access_token');
    if (!accessToken) {
        onFailure(new Error('No access token'));
    }

    const urlEncodeData = urlEncodeExtendPageData(vignet, paymentMethod, iban, ibanHolder);
    console.log('Extend vignet redirect url:', Util.getPageUrl(true) + '/done');

    fetch(Config.api.baseUrl + '/api-extend/payment/nl', {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        }),
        body: JSON.stringify({
            vignet_id: vignet.vignet_id,
            payment_type_id: paymentMethod.id,
            return_url_cancel: Util.getPageUrl() + '/' + urlEncodeData + '/cancel',
            return_url_reject: Util.getPageUrl() + '/' + urlEncodeData + '/reject',
            return_url_success: Util.getPageUrl(true) + '/done',
            return_url_error: Util.getPageUrl() + '/' + urlEncodeData + '/error',
            account_holder: ibanHolder,
            account_number: iban
        })
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const sendRestitutionRequest = (VignetID: string, cancelationReasonID: string, data: string, onSuccess: Function, onFailure: Function) => {
    const accessToken = window.localStorage.getItem('access_token');
    if (!accessToken) {
        onFailure(new Error('No access token'));
    }
    fetch(Config.api.baseUrl + '/api-cancellation/save', {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        }),
        body: JSON.stringify({
            VignetID: VignetID,
            ReasonID: cancelationReasonID,
            document: data
        })
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
};

const sendMooringRequest = (initials: string, prefix: string, lastName: string, vignetID: string, phoneNumber: string, email: string, paymentTypeID: string, cardNumber: string, productID: string, onSuccess: Function, onFailure: Function) => {
    const accessToken = window.localStorage.getItem('access_token');
    if (!accessToken) {
        onFailure(new Error('No access token'));
    }

    const body = {
        initials: initials,
        prefix: prefix,
        lastname: lastName,
        vignet: vignetID,
        phonenumber: phoneNumber,
        email: email,
        payment_type_id: paymentTypeID?.split(':')[1],
        card_service_code: cardNumber,
        product_id: productID,
    }

    const urlEncodeData = urlEncodeMooringPageData(initials, prefix, lastName, vignetID, phoneNumber, email, paymentTypeID, cardNumber, productID);

    fetch(Config.api.baseUrl + '/api-mooring/payment-mooring/en', {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        }),
        body: JSON.stringify({
            ...body,
            return_url_cancel: Util.getPageUrl() + '/' + urlEncodeData + '/cancel',
            return_url_reject: Util.getPageUrl() + '/' + urlEncodeData + '/reject',
            return_url_success: Util.getPageUrl() + '/done',
            return_url_error: Util.getPageUrl() + '/' + urlEncodeData + '/error',
        })
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const urlEncodeExtendPageData = (vignet: TVignet, paymentMethod: TPaymentType, iban: string | undefined, ibanHolder: string | undefined) => {
    const data = {
        vignetID: vignet.vignet_id,
        paymentMethodID: paymentMethod.id,
        iban: iban,
        ibanHolder: ibanHolder,
    }    
    return window.btoa(JSON.stringify(data));
}

const urlEncodeMooringPageData = (initials: string, prefix: string, lastName: string, vignetID: string, phoneNumber: string, email: string, paymentTypeID: string, cardNumber: string, productID: string) => {
    const data = {
        firstName: initials,
        middleName: prefix,
        lastName: lastName,
        vignetNumber: vignetID,
        phoneNumber: phoneNumber,
        email: email,
        paymentTypeID: paymentTypeID,
        cardServiceCode: cardNumber,
        productID: productID
    }
    return window.btoa(JSON.stringify(data));
}

const urlEncodeTransitPageData = (initials: string, prefix: string, lastName: string, address: string, houseNumber: string, addition: string, zipcode: string, city: string, countryID: string, phoneNumber: string, email: string, company: string, kvk: string, vignetTypeID: string, deliveryTypeID: string, paymentTypeID: string, cardNumber: string, bsn: string) => {
    const data = {
        initials: initials,
        middleName: prefix,
        lastName: lastName,
        address: address,
        houseNumber: houseNumber,
        addition: addition,
        zipcode: zipcode,
        city: city,
        countryID: countryID,
        phoneNumber: phoneNumber,
        email: email,
        company: company,
        kvk: kvk,
        vignetTypeID: vignetTypeID,
        deliveryTypeID: deliveryTypeID,
        paymentTypeID: paymentTypeID,
        cardServiceCode: cardNumber,
        bsn: bsn,
    }
    return window.btoa(JSON.stringify(data));
}

const sendPassageRequest = (initials: string, prefix: string, lastName: string, address: string, houseNumber: string, addition: string, zipcode: string, city: string, countryID: string, phoneNumber: string, email: string, company: string, kvk: string, vignetTypeID: string, deliveryTypeID: string, paymentTypeID: string, cardNumber: string, bsn: string, onSuccess: Function, onFailure: Function) => {
    const accessToken = window.localStorage.getItem('access_token');
    if (!accessToken) {
        onFailure(new Error('No access token'));
    }
    const body = {
        initials: initials,
        prefix: prefix,
        lastname: lastName,
        address: address,
        number: houseNumber,
        addition: addition,
        postcode: zipcode,
        city: city,
        country_id: countryID,
        phonenumber: phoneNumber,
        email: email,
        company: company,
        kvk: kvk,
        vignet_type_id: vignetTypeID,
        delivery_type_id: deliveryTypeID.split(':')[1],
        payment_type_id: paymentTypeID.split(':')[1],
        card_service_code: cardNumber,
        bsn: bsn,
    }

    const urlEncodeData = urlEncodeTransitPageData(initials, prefix, lastName, address, houseNumber, addition, zipcode, city, countryID, phoneNumber, email, company, kvk, vignetTypeID, deliveryTypeID, paymentTypeID, cardNumber, bsn);

    fetch(Config.api.baseUrl + '/api-passage/payment-vignet', {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        }),
        body: JSON.stringify({
            ...body,
            return_url_cancel: Util.getPageUrl() + '/' + urlEncodeData + '/cancel',
            return_url_reject: Util.getPageUrl() + '/' + urlEncodeData + '/reject',
            return_url_success: Util.getPageUrl() + '/done',
            return_url_error: Util.getPageUrl() + '/' + urlEncodeData + '/error',
        })
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const validateMooringVignet = (vignetCode: string, onSuccess: Function, onFailure: Function) => {
    const accessToken = window.localStorage.getItem('access_token');
    if (!accessToken) {
        onFailure(new Error('No access token'));
    }
    fetch(Config.api.baseUrl + '/api-mooring/check-vignet/nl', {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        }),
        body: JSON.stringify({
            vignet: vignetCode
        })
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
};

const activateVignet = (vignetCode: string, activationCode: string, onSuccess: Function, onFailure: Function) => {
    fetch(Config.api.baseUrl + '/api-activate/check-vignet/nl', {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
            vignet_type: '1',
            vignet: vignetCode,
            pincode: activationCode
        })
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const validateObject = (object_drive_type_id: string, object_width: string, object_length: string, object_name: string, object_type_id: string, onSuccess: Function, onFailure: Function) => {
    const accessToken = window.localStorage.getItem('access_token');
    fetch(Config.api.baseUrl + '/api-activate/validate-object/nl', {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        }),
        body: JSON.stringify({
            object_drive_type_id: object_drive_type_id,
            object_width: object_width,
            object_length: object_length,
            object_name: object_name,
            object_type_id: object_type_id
        })
    }).then(async (response: Response) => {
        if(response.status < 200 || response.status >= 300) {
            throw new Error((await response.json()).errors.non_field_errors);
        }
        return true;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const validateOwner = (initials: string, prefix: string, last_name: string, email: string, confirmEmail: string, bsn: string, mobile_phone: string, vignet_type: string, country_id: string, address: string, addition: string, city: string, number: string, postcode: string, onSuccess: Function, onFailure: Function) => {
    const accessToken = window.localStorage.getItem('access_token');
    fetch(Config.api.baseUrl + '/api-activate/validate-owner/nl', {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        }),
        body: JSON.stringify({
            initials: initials,
            prefix: prefix,
            last_name: last_name,
            email: email,
            confirmEmail: confirmEmail,
            bsn: bsn,
            mobile_phone: mobile_phone,
            vignet_type: vignet_type,
            country_id: country_id,
            address: address,
            addition: addition,
            city: city,
            number: number,
            postcode: postcode,
            company_name: ''
        })
    }).then(async (response: Response) => {
        if(response.status < 200 || response.status >= 300) {
            throw new Error((await response.json()).errors.non_field_errors);
        }
        return true;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const saveData = (initials: string, prefix: string, last_name: string, email: string, confirmEmail: string, bsn: string, mobile_phone: string, vignet_type: string, country_id: string, address: string, addition: string, city: string, number: string, postcode: string, object_drive_type_id: string, object_type_id: string, object_length: string, object_width: string, object_name: string, onSuccess: Function, onFailure: Function) => {
    const accessToken = window.localStorage.getItem('access_token');
    fetch(Config.api.baseUrl + '/api-activate/save-data/nl', {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        }),
        body: JSON.stringify({
            initials: initials,
            prefix: prefix,
            last_name: last_name,
            email: email,
            confirmEmail: confirmEmail,
            bsn: bsn,
            mobile_phone: mobile_phone,
            vignet_type: vignet_type,
            country_id: country_id,
            address: address,
            addition: addition,
            city: city,
            number: number,
            postcode: postcode,
            company_name: '',
            object_drive_type_id: object_drive_type_id,
            object_type_id: object_type_id,
            object_width: object_width,
            object_length: object_length,
            object_name: object_name
        })
    }).then(async (response: Response) => {
        if(response.status < 200 || response.status >= 300) {
            throw new Error((await response.json()).errors.non_field_errors);
        }
        return true;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const uploadPhotos = (files: any, onSuccess: Function, onFailure: Function) => {
    const formData = new FormData();
    files.forEach((file: any) => {
        if(file[0] && file[0].data) {
            console.log(file[0].native);
            formData.append('photos[]', file[0].native, file[0].name);
        }
    });
    formData.append('photos[]', 'Ontheffing');

    const accessToken = window.localStorage.getItem('access_token');
    fetch(Config.api.baseUrl + '/api-activate/upload', {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        }),
        body: formData
    }).then(async (response: Response) => {
        if(response.status < 200 || response.status >= 300) {
            throw new Error((await response.json()).errors.non_field_errors);
        }
        return true;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const VignetApi = {
    getVignet: getVignet,
    getVignets: getVignets,
    cancelVignet: cancelVignet,
    extendVignet: extendVignet,
    sendRestitutionRequest: sendRestitutionRequest,
    sendMooringRequest: sendMooringRequest,
    sendPassageRequest: sendPassageRequest,
    validateMooringVignet: validateMooringVignet,
    updateVignet: updateVignet,
    activateVignet: activateVignet,
    validateObject: validateObject,
    validateOwner: validateOwner,
    saveData: saveData,
    uploadPhotos: uploadPhotos
}

export default VignetApi;
export type { TVignet, TCheckVignet };