import * as React from 'react';

import style from './AgreementBox.module.css';

type TAgreementBox = {
    text: string,
    hideCheckBox?: boolean
    onChange: Function
    validity?: boolean
    disabled?: boolean
    children?: JSX.Element | JSX.Element[]
    defaultChecked?: boolean
}

const AgreementBox = (props: TAgreementBox) => {
    const [checked, setChecked] = React.useState<boolean>(props.defaultChecked || false);

    const handleOnClick = (checked: boolean) => {
        if(props.hideCheckBox || props.disabled){
            return;
        }
        props.onChange(checked);
        setChecked(checked);
    };
    
    return (
        <div id={style.container} className={props.validity === false ? style.invalid : style.valid} onClick={()=>{handleOnClick(!checked)}}>
            <input disabled={props.disabled} className={props.hideCheckBox ? style.hiddenInput : style.input} type={'checkbox'} onChange={()=>{handleOnClick(!checked)}} checked={checked}></input>
            <div className={style.text}>{props.text}{props.children}</div>
        </div>
    )
};

export default AgreementBox;