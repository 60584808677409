import * as React from 'react';
import DataApi, { TPaymentType } from '../../api/DataApi';
import AgreementBox from '../agreement-box/AgreementBox';
import ChangeVignetPagePaymentDetails from '../change-vignet-page-payment-details/ChangeVignetPagePaymentDetails';
import KeyValuePair from '../key-value-pair/KeyValuePair';

import style from './ChangeVignetPagePaymentTerms.module.css';

type TChangeVignetPageSummary = {
    onChange: Function,
    validity: boolean,
    disabled: boolean
    iban?: string,
    ibanHolder?: string
    setExpansion: Function,
    expansion: number,
    setEditMode: Function
    returnPaymentMethodID?: string
    setPageError: Function
    paymentMethod: TPaymentType | undefined
}

const ChangeVignetPagePaymentTerms = (props: TChangeVignetPageSummary) => {
    const [paymentTypes, setPaymentTypes] = React.useState<TPaymentType[] | undefined>(() => {
        DataApi.getPaymentTypes((json: any) => {
            setPaymentTypes(json);
            if(props.returnPaymentMethodID) {
                const index = (json as TPaymentType[]).findIndex((paymentType: TPaymentType) => {return paymentType.id === props.returnPaymentMethodID});
                const paymentType = (json as TPaymentType[])[index];
                handleOnChange(paymentType, index > 0);
            }
        }, (error: Error) => {
            console.error(error);
        })
        return undefined;
    });
    
    const [paymentMethod, setPaymentMethod] = React.useState<TPaymentType | undefined>(props.paymentMethod);
    const [showAdditionalFields, setShowAdditionalFields] = React.useState<boolean>(props.paymentMethod?.id !== paymentTypes?.[0]?.id);

    const [iban, setIban] = React.useState<string | undefined>(props.iban);
    const [ibanHolder, setIbanHolder] = React.useState<string | undefined>(props.ibanHolder);

    const [hasAgreed, setHasAgreed] = React.useState<boolean>(false);

    const handleOnChange = (paymentType: TPaymentType, showAdditionalFields: boolean) => {
        props.onChange(paymentType, iban, ibanHolder, hasAgreed, showAdditionalFields);
        setShowAdditionalFields(showAdditionalFields);
        setPaymentMethod(paymentType);
    };

    const handleOnChangeIban = (e: any) => {
        props.onChange(paymentMethod, e.target.value, ibanHolder, hasAgreed, showAdditionalFields);
        setIban(e.target.value);
    }

    const handleOnChangeIbanHolder = (e: any) => {
        props.onChange(paymentMethod, iban, e.target.value, hasAgreed, showAdditionalFields);
        setIbanHolder(e.target.value);
    }

    const handleOnChangeAgreement = (checked: boolean) => {
        props.onChange(paymentMethod, iban, ibanHolder, checked, showAdditionalFields);
        setHasAgreed(checked);
    }
    
    return (
        <React.Fragment>
            <div className={style.pageSubHeader}>Betaalmethode</div>
            <div className={style.text}>U kunt het bedrag in 1 keer betalen via iDEAL of in 4 delen. Kiest u voor betalen in 4 delen? Dan betaalt u via automatische incasso. Het eerste deel van het bedrag wordt binnen 48 uur van uw rekening afgeschreven. De volgende 3 betalingen volgen elke maand daarop.</div>
            <div id={style.paymentTermSelection}>
                <div className={style.pageSubHeader}>Betalen{props.expansion !== 2 ? <div className={style.editButton} onClick={() => { props.setExpansion(2); }}>Wijzig</div> : <React.Fragment></React.Fragment>}</div>
                {
                    paymentTypes?.map((paymentType: TPaymentType, index: number) => {
                        return (
                            <div key={'payment_type_' + index} className={`${style.paymentTermOption} ${!props.validity && !paymentMethod ? style.invalid : style.valid}`}>
                                <input defaultChecked={paymentType.id === paymentMethod?.id} disabled={props.disabled} name={'terms'} type={'radio'} value={paymentType.id + ':' + paymentType.name} onChange={(e: any) => {handleOnChange(paymentType, index > 0)}}></input>
                                <div>{paymentType.name}</div>
                            </div>
                        )
                    })
                }
                
                {paymentMethod && paymentMethod?.id !== paymentTypes?.[0]?.id ?
                    <React.Fragment>
                        <ChangeVignetPagePaymentDetails iban={props.iban} ibanHolder={props.ibanHolder} validity={props.validity} disabled={props.disabled} onChangeIban={handleOnChangeIban} onChangeIbanHolder={handleOnChangeIbanHolder}></ChangeVignetPagePaymentDetails>
                        <AgreementBox defaultChecked={hasAgreed} disabled={props.disabled} validity={props.validity || hasAgreed} onChange={handleOnChangeAgreement} text={'Ik geef de gemeente Amsterdam toestemming om de kosten van mijn vignet in 4 delen van mijn bankrekening af te schrijven.'}></AgreementBox>
                    </React.Fragment>
                    : ''}
            </div>
        </React.Fragment>
    )
};

export default ChangeVignetPagePaymentTerms;