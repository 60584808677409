import Config from "../config/Config";

type TDriveType = {
    drive_type_id: string,
    drive_type_name: string
}

type TIssuer = {
    DebtorBankid: string,
    DebtorBankName: string
}

type TCard = {
    ServiceCode: string,
    CardName: string
}

type TPaymentType = {
    id: string,
    name: string,
    issuer_list?: TIssuer[]
    card_list?: TCard[]
}

type TReason = {
    ReasonID: string,
    Name: string,
    RestitutionPossible: boolean,
    RequestPossible: boolean,
    ExplanationText: string,
    TextDocument: string
}

type TCancelationType = {
    cancellation: TReason[],
    change: TReason[]
}

type TVignetType = {
    id: string,
    cost: string,
    name: string,
    displayName: string,
    description: string
}

type TCountry = {
    id: string,
    name: string
}

type TDeliveryType = {
    id: string,
    name: string,
    description: string,
    price: string
}

type TProductType = {
    ProductID: string,
    Name: string,
    Price: string,
    Description: string
}

type TPickupLocation = {
    Address: string,
    City: string,
    ExtraText: string,
    Latitude: string,
    Longitude: string,
    Name: string,
    Postcode: string,
    Website: string,
    lat?: number,
    lng?: number
}

const getCountries = (onSuccess: Function, onFailure: Function) => {
    fetch(Config.api.baseUrl + '/api-passage/get-countries', {
        method: 'GET',
        mode: 'cors'
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json.countries);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const getActivateDriveTypes = (onSuccess: Function, onFailure: Function) => {
    const accessToken = window.localStorage.getItem('access_token');
    if (!accessToken) {
        return;
    }
    fetch(Config.api.baseUrl + '/api-activate/get-drive-types/nl', {
        method: 'GET',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        })
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json.drive_types);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const getActivateObjectTypes = (onSuccess: Function, onFailure: Function) => {
    const accessToken = window.localStorage.getItem('access_token');
    if (!accessToken) {
        return;
    }
    fetch(Config.api.baseUrl + '/api-activate/get-object-types/nl', {
        method: 'GET',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        })
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json.object_types);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const getDriveTypes = (onSuccess: Function, onFailure: Function) => {
    const accessToken = window.localStorage.getItem('access_token');
    if (!accessToken) {
        return;
    }
    fetch(Config.api.baseUrl + '/api-extend/get-drive-types/nl', {
        method: 'GET',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        })
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json.drive_types);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const getPaymentTypes = (onSuccess: Function, onFailure: Function) => {
    const accessToken = window.localStorage.getItem('access_token');
    if (!accessToken) {
        return;
    }
    fetch(Config.api.baseUrl + '/api-extend/payment-types/nl', {
        method: 'GET',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        })
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json.types);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const getMooringPaymentTypes = (onSuccess: Function, onFailure: Function) => {
    fetch(Config.api.baseUrl + '/api-mooring/get-payment-types', {
        method: 'GET',
        mode: 'cors'
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json.payment_types);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const getCancelationTypes = (onSuccess: Function, onFailure: Function) => {
    fetch(Config.api.baseUrl + '/api-cancellation/reasons', {
        method: 'GET',
        mode: 'cors'
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const getVignetTypes = (onSuccess: Function, onFailure: Function) => {
    fetch(Config.api.baseUrl + '/api-passage/get-vignet-types', {
        method: 'GET',
        mode: 'cors'
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json.vignet_types);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const getMooringTypes = (onSuccess: Function, onFailure: Function) => {
    fetch(Config.api.baseUrl + '/api-mooring/get-products', {
        method: 'GET',
        mode: 'cors'
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json.products);
    }).catch((error: Error) => {
        onFailure(error);
    });
}


const getDeliveryTypes = (countryID: string, onSuccess: Function, onFailure: Function) => {
    fetch(Config.api.baseUrl + '/api-passage/get-delivery-types/nl', {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
            country_id: countryID
        })
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json.delivery_types);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const uploadDocuments = (vignetID: string, documents: string[], onSuccess: Function, onFailure: Function) => {
    const accessToken = window.localStorage.getItem('access_token');
    if (!accessToken) {
        return;
    }
    fetch(Config.api.baseUrl + '/api-extend/upload-documents', {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        }),
        body: JSON.stringify({
            documents: documents,
            vignet_id: vignetID
        })
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json.delivery_types);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const getExtendSummary = (onSuccess: Function, onFailure: Function) => {
    const accessToken = window.localStorage.getItem('access_token');
    if (!accessToken) {
        return;
    }
    fetch(Config.api.baseUrl + '/api-extend/get-summary/nl', {
        method: 'GET',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        })
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const getMooringSummary = (onSuccess: Function, onFailure: Function) => {
    const accessToken = window.localStorage.getItem('access_token');
    if (!accessToken) {
        return;
    }
    fetch(Config.api.baseUrl + '/api-mooring/get-summary/nl', {
        method: 'GET',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        })
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const getPassageSummary = (onSuccess: Function, onFailure: Function) => {
    const accessToken = window.localStorage.getItem('access_token');
    if (!accessToken) {
        return;
    }
    fetch(Config.api.baseUrl + '/api-passage/get-summary/nl', {
        method: 'GET',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        })
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const getRestitutionSummary = (vignetId: string, onSuccess: Function, onFailure: Function) => {
    const accessToken = window.localStorage.getItem('access_token');
    fetch(Config.api.baseUrl + '/api-cancellation/get-summary', {
        method: 'POST',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        }),
        body: JSON.stringify({VignetID: vignetId})
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const getActivationSummary = (onSuccess: Function, onFailure: Function) => {
    const accessToken = window.localStorage.getItem('access_token');
    fetch(Config.api.baseUrl + '/api-activate/get-summary', {
        method: 'GET',
        mode: 'cors',
        headers: new Headers({
            Authorization: 'Bearer ' + accessToken
        }),
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
}


const getPickupLocations = (onSuccess: Function, onFailure: Function) => {
    fetch(Config.api.baseUrl + '/api-passage/get-pickup-locations/nl', {
        method: 'GET',
        mode: 'cors'
    }).then(async (response: Response) => {
        const json = await response.json();
        if (json.errors?.non_field_errors) {
            throw new Error(json.errors.non_field_errors);
        }
        return json.pickup_locations;
    }).then((json: any) => {
        onSuccess(json);
    }).catch((error: Error) => {
        onFailure(error);
    });
}

const DataApi = {
    getCountries: getCountries,
    getDriveTypes: getDriveTypes,
    getPaymentTypes: getPaymentTypes,
    getCancelationTypes: getCancelationTypes,
    getMooringPaymentTypes: getMooringPaymentTypes,
    getVignetTypes: getVignetTypes,
    getDeliveryTypes: getDeliveryTypes,
    getMooringTypes: getMooringTypes,
    uploadDocuments: uploadDocuments,
    getExtendSummary: getExtendSummary,
    getMooringSummary: getMooringSummary,
    getPassageSummary: getPassageSummary,
    getPickupLocations: getPickupLocations,
    getActivateDriveTypes: getActivateDriveTypes,
    getActivateObjectTypes: getActivateObjectTypes,
    getRestitutionSummary: getRestitutionSummary,
    getActivationSummary: getActivationSummary
}

export default DataApi;
export type { TDriveType, TPaymentType, TIssuer, TCard, TCancelationType, TVignetType, TCountry, TDeliveryType, TProductType, TReason, TPickupLocation }