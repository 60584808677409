import * as React from 'react';

import style from './KeyValuePair.module.css';

enum EEditMode {
    TEXT,
    DROPDOWN,
    RADIO
}

type TEditOption = {
    text: string,
    value: string
}

type TEditOptions = {
    mode: EEditMode,
    default?: number
    options: TEditOption[]
}

type TKeyValuePair = {
    label: string,
    value: string,
    className?: string,
    editable?: Function,
    inline?: boolean,
    horizontal?: boolean,
    onEnableEdit?: Function,
    editOptions?: TEditOptions,
    children?: any
}

const KeyValuePair = (props: TKeyValuePair) => {
    const [validity, setValidity] = React.useState<boolean | undefined>(true);

    const getInputField = () => {
        if (props.editOptions?.mode === EEditMode.DROPDOWN) {
            return (
                <select id={style.select} defaultValue={props.editOptions.options[props.editOptions.default || 0].value} onChange={(e: any)=>{props.editable?.(e)}}>
                    {
                        props.editOptions.options.map((option: TEditOption, index: number) => {
                            return (
                                <option key={'key_value_pair_' + props.label + '_drop_down_option_' + index} value={option.value}>{option.text}</option>
                            )
                        })
                    }
                </select>
            )
        }

        if (props.editOptions?.mode === EEditMode.RADIO) {
            return (
                props.editOptions.options.map((option: TEditOption, index: number) => {
                    return (
                        <div className={style.radioContainer} key={'key_value_pair_' + props.label + '_radio_option_' + index}>
                            <input name={props.label} type='radio' value={option.value} defaultChecked={props.editOptions?.default === index} onChange={(e: any)=>{props.editable?.(e)}}></input>
                            <div>{option.text}</div>
                        </div>
                    )
                })
            )
        }

        return <input id={props.horizontal ? style.horizontalValue : style.value} defaultValue={props.value} className={validity ? style.valid : style.invalid} onChange={(e: any) => { setValidity(props.editable?.(e)) }}></input>
    }

    return (
        <div id={props.inline ? style.inlineContainer : style.container} className={style.className}>
            <div id={props.horizontal ? style.horizontalKey : style.key}>{props.label}{props.onEnableEdit ? <div id={style.editButton} onClick={(e: any) => { props.onEnableEdit?.(e) }}>Wijzig</div> : ''}</div>
            {props.editable ? getInputField() : <div id={props.horizontal ? style.horizontalValue : style.value}>{props.value} {props.children}</div>}
        </div>
    )
};

export default KeyValuePair;
export { EEditMode }
export type { TKeyValuePair, TEditOptions, TEditOption };