import * as React from 'react';
import FormButtonBar from '../../containers/form-button-bar/FormButtonBar';

import style from './CenteredForm.module.css';

type TCenteredForm = {
    children?: JSX.Element | JSX.Element[]
    onNext?: Function,
    onPrevious?: Function,
    onPreviousText?: string,
    locked?: boolean,
    onNextText?: string
}

const CenteredForm = (props: TCenteredForm) => {
    return (
        <div id={style.container}>
            <div id={style.form}>
                {props.children}
                {props.onNext || props.onPrevious ? <FormButtonBar locked={props.locked} onNext={props.onNext} onNextText={props.onNextText} onPreviousText={props.onPreviousText} onPrevious={props.onPrevious}></FormButtonBar> : <React.Fragment></React.Fragment>}
            </div>
        </div>
    )
};

export default CenteredForm;