import * as React from 'react';

import { TCard, TPaymentType } from '../../api/DataApi';

import style from './PaymentTypeSelector.module.css';

type TPaymentTypeProps = {
    paymentType?: string;
    paymentTypes?: TPaymentType[];
    serviceCode?: string
    onChangePaymentType: Function;
    onChangeServiceCode: Function;
    validityStyling: string;
}

const PaymentTypeSelector = (props: TPaymentTypeProps) => {
    const getCardListDOM = (entry: TPaymentType) => {
        if (!entry.card_list) {
            return <React.Fragment></React.Fragment>
        }
        return (
            <select value={props.serviceCode} key={'card_list_option_select'} id={style.cardListContainer} onChange={(e: any) => {props.onChangeServiceCode(e)}}>
                <option key={'card_list_option_blank'}></option>
                {
                    entry.card_list?.map((card: TCard, index: number) => {
                        return (
                            <option key={'card_list_option_' + index} value={card.ServiceCode}>{card.CardName}</option>
                        )
                    })
                }
            </select>
        )
    };
    
    return (
        <React.Fragment>
            {
                props.paymentTypes?.map((entry: TPaymentType, index: number) => {
                    return (
                        <div key={'payment_type_container_' + index}>
                            <div key={'payment_type_' + index} className={`${style.selectTypeContainer} ${props.validityStyling}`}>
                                <input defaultChecked={props.paymentType === entry.name + ':' + entry.id} type={'radio'} name={'paymentType'} value={entry.name + ":" + entry.id} onChange={(e: any) => {props.onChangePaymentType(e)}}></input>
                                <div>{entry.name}</div>
                            </div>
                            {
                                props.paymentType && props.paymentType === entry.name + ':' + entry.id ? getCardListDOM(entry) : <React.Fragment></React.Fragment>
                            }
                        </div>
                    )
                })
            }
        </React.Fragment>
    )
}

export default PaymentTypeSelector;