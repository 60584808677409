import * as React from 'react';
import { useNavigate, useParams } from 'react-router';
import DataApi, { TCancelationType, TReason } from '../../api/DataApi';
import UserApi, { TOwner } from '../../api/UserApi';
import VignetApi, { TVignet } from '../../api/VignetApi';
import AgreementBox from '../../components/agreement-box/AgreementBox';
import CancelVignetPageSummary, { TCancelVignetPageSummary } from '../../components/cancel-vignet-page-summary/CancelVignetPageSummary';
import CenteredForm from '../../components/centered-form/CenteredForm';
import DownloadSummaryButton from '../../components/download-summary/DownloadSummary';
import InlineRadioSelectionField, { TOption } from '../../components/inline-radio-selection-field/InlineRadioSelectionField';
import PageHeader from '../../components/page-header/PageHeader';
import Util from '../../util/Util';
import FormStepper, { TStep } from '../form-stepper/FormStepper';

import style from './CancelVignetPage.module.css';

const CancelVignetPage = () => {
    const navigate = useNavigate();
    const params = useParams();

    const [expansion, setExpansion] = React.useState<number>(0);
    const [reason, setReason] = React.useState<string | undefined>(undefined);

    const [validity, setValidity] = React.useState<boolean>(true);

    const [hasAgreedToCancelation, setHasAgreedToCancelation] = React.useState<boolean>(false);

    const [hasAgreedUserAgreement, setHasAgreedUserAgreement] = React.useState<boolean>(false); 
    const [hasAgreedDetailsCorrect, setHasAgreedDetailsCorrect] = React.useState<boolean>(false); 

    const [pageError, setPageError] = React.useState<string | undefined>(undefined);

    const [locked, setLocked] = React.useState<boolean>(false);

    const [cancelationTypes, setCancelationTypes] = React.useState<TCancelationType | undefined>(() => {
        DataApi.getCancelationTypes((cancelationTypes: TCancelationType) => {
            setCancelationTypes(cancelationTypes);
        }, (error: Error) => {
            console.error(error);
        })
        return undefined;
    })

    const [vignet, setVignet] = React.useState<TVignet | undefined>(() => {
        VignetApi.getVignet(params.vignetID || '', (json: any) => {
            setVignet(json);
        }, (error: Error) => {
            console.error(error);
        });
        return undefined;
    });

    const [owner, setOwner] = React.useState<TOwner | undefined>(() => {
        UserApi.getOwner((json: any) => {
            setOwner(json);
        }, (error: Error) => {
            console.error(error);
        });
        return undefined;
    });

    if (!vignet || !owner) {
        return (
            <div>Loading..</div>
        )
    }

    const handleOnNext = () => {
        setValidity(true);
        setPageError('');
        if(!reason) {
            setPageError('U moet een reden voor uw opzegging geven.')
            setValidity(false);
            return;
        }

        if(!hasAgreedToCancelation){
            setPageError('Ga akkoord met het bovenstaande om verder te gaan.');
            setValidity(false);
            return;
        }

        if (expansion === 1 && (!hasAgreedUserAgreement || !hasAgreedDetailsCorrect)) {
            setValidity(false);
            return;
        }
        
        if (expansion === 0) {
            setExpansion(expansion + 1);
            return;
        }
        setLocked(true);
        VignetApi.cancelVignet(params.vignetID || '', reason || '', (json: any) => {
            navigate('done');
        }, (error: Error) => {
            setPageError(error.message);
            setLocked(false);
        })
    };

    const handleOnPrevious = () => {
        setPageError(undefined);
        if (expansion > 0) {
            setExpansion(expansion - 1);
            return;
        }
        navigate('/vignet-overview')
    };

    const getSummary = () => {
        const summary: TCancelVignetPageSummary = {
            bsn: owner.bsn,
            initials: owner.initials,
            prefix: owner.prefix,
            lastName: owner.lastname,
            street: owner.address,
            houseNumber: owner.number,
            addition: owner.addition,
            zipCode: owner.postcode,
            city: owner.city,
            phoneNumber: owner.mobilephone,
            email: owner.email,
            vignetNumber: vignet.vignet_number,
            objectName: vignet.object_name,
            reason: reason || '',
            validity: validity,
            setHasAgreedUserAgreement: setHasAgreedUserAgreement,
            setHasAgreedDetailsCorrect: setHasAgreedDetailsCorrect
        }
        return summary;
    }

    const steps: TStep[] = [
        {text: 'Uw gegevens'},
        {text: 'Vignetoverzicht'},
        {text: 'Opzeggen ' + vignet.vignet_number},
        {text: 'Bedankt'}
    ]
    
    const getCancelationOptions = () => {
        if(!cancelationTypes || !cancelationTypes.cancellation || cancelationTypes.cancellation.length <= 0){
            return <React.Fragment></React.Fragment>
        }
        const otherIndex = cancelationTypes.cancellation.findIndex((reason: TReason) => {
            return reason.Name === 'Opzegging overig';
        });
        if(otherIndex >= 0 && otherIndex !== cancelationTypes.cancellation.length-1) {
            const other: TReason | undefined = cancelationTypes.cancellation.splice(otherIndex, 1)[0];
            cancelationTypes.cancellation.push(other);
        }
        return (
            <select id={style.cancelationDropdownMenu} onChange={(e: any) => { setReason(e.target.value) }}>
                <option key={'cancelation_type_option_default_blank'}></option>
                {/* <option key={'cancelation_type_option_default_sold'} value='verkocht'>Boot verkocht</option> */}
                {
                    cancelationTypes?.cancellation.map((cancelationType: TReason, index: number) => {
                        const reasonName = Util.capitalize(cancelationType.Name.replace('Opzegging ', ''));
                        return (
                            <option key={'cancelation_type_option_' + index} value={reasonName}>{reasonName}</option>
                        )
                    })
                }
                {/* <option key={'cancelation_type_option_default_other'} value={'other'}>{'overig'}</option> */}
            </select>
        )
    }

    return (
        <React.Fragment>
            <PageHeader logout={true} className={style.pageHeader}></PageHeader>
            <div id={style.container}>
                <FormStepper steps={steps} currentStep={2}></FormStepper>
                <CenteredForm locked={locked} onPrevious={handleOnPrevious} onNext={handleOnNext}>
                    <div id={style.header}>Opzeggen {vignet?.vignet_number}</div>
                    <div id={style.subHeader}>Hieronder ziet u uw vignet dat u wilt opzeggen. Geef ook aan waarom u het wilt opzeggen.</div>
                    <div id={style.vignetTable}>
                        <div id={style.vignetTableHeader}>
                            <div className={style.vignetColumn}>Naam van uw boot</div>
                            <div className={style.vignetColumn}>Vignetnummer</div>
                            <div className={style.vignetColumn}>Reden opzeggen</div>
                        </div>
                        <div id={style.vignetTableRow}>
                            <div className={style.vignetTableRowItem}>{vignet.object_name}</div>
                            <div className={style.vignetTableRowItem}>{vignet.vignet_number}</div>
                            {getCancelationOptions()}
                        </div>
                        <div className={style.vignetTableSeparator}></div>
                    </div>
                    {expansion === 0 ? <AgreementBox defaultChecked={hasAgreedToCancelation} validity={validity || hasAgreedToCancelation} onChange={(checked: boolean) => { setHasAgreedToCancelation(checked) }} text={'Ik ga ermee akkoord dat het vignet dat hierboven staat wordt opgezegd. Ik begrijp dat ik dan met dit vignet niet meer mag varen en afmeren in Amsterdam.'}></AgreementBox> : <React.Fragment></React.Fragment>}
                    {expansion > 0 ? <CancelVignetPageSummary {...getSummary()}></CancelVignetPageSummary> : <React.Fragment></React.Fragment>}
                    {pageError ? <div id={style.pageError}>{pageError}</div> : <React.Fragment></React.Fragment>}
                </CenteredForm>
            </div>
        </React.Fragment>
    )
};

export default CancelVignetPage;