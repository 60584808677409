import * as React from 'react';
import { useNavigate } from 'react-router';
import CenteredForm from '../../components/centered-form/CenteredForm';
import PageHeader from '../../components/page-header/PageHeader';
import TitleLineComponent from '../../components/page-layout/TitleLineComponent';
import FormStepper from '../form-stepper/FormStepper';

import style from './ResendSmsPage.module.css';
import ThreeStrikesApi from '../../api/ThreeStrikesApi';

const ResendSmsPage = () => {

    const [phoneNumber, setPhoneNumber] = React.useState<string>('');

    const [pageError, setPageError] = React.useState<string | undefined>(undefined);

    const [done, setDone] = React.useState<boolean>(false);

    const navigate = useNavigate();

    const handleOnNext = () => {
        setPageError(undefined);
        if(!phoneNumber || phoneNumber.trim().length <= 0) {
            setPageError('Vul uw telefoonnummer hierboven in.');
            return;
        }
        if(done) {
            navigate('/registration');
            return;
        }

        ThreeStrikesApi.requestNewPincode(phoneNumber, (json: any) => {
            setDone(true);
        }, (error: Error) => {
            setPageError(error.message);
        })
    }

    if(done) {
        return (
            <React.Fragment>
                <PageHeader className={style.pageHeader}></PageHeader>
                <div id={style.container}>
                    <CenteredForm onPrevious={()=>{setDone(false)}} onNext={handleOnNext}>
                        <TitleLineComponent text={"Aanvraag Binnenhavengeldvignet"} />
                        <div id={style.header}>Eenmalig wachtwoord verstuurd</div>
                        <div id={style.subHeader}>
                            Binnen enkele minuten ontvangt u een sms-bericht met een nieuw eenmalig wachtwoord.
                            <br></br>
                            <br></br>
                            Heeft u nog steeds geen sms met wachtwoord ontvangen?
                            <br></br>
                            Stuur een e-mail naar <a href="mailto:vaarvignet@amsterdam.nl">vaarvignet@amsterdam.nl</a>.
                        </div>
                        <div id={style.pageError}>{pageError}</div>
                    </CenteredForm>
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <PageHeader className={style.pageHeader}></PageHeader>
            <div id={style.container}>
                <CenteredForm onPrevious={()=>{navigate('/registration')}} onNext={handleOnNext}>
                    <TitleLineComponent text={"Aanvraag Binnenhavengeldvignet"} />
                    <div id={style.header}>Eenmalig wachtwoord opnieuw versturen</div>
                    <div id={style.subHeader}>
                        Vul alstublieft uw telefoonnummer in.
                    </div>

                    <div className={style.validationLabel}>Telefoonnummer</div>
                    <input type='text' value={phoneNumber} onChange={(e) => { setPhoneNumber(e.target.value) }}></input>

                    <div id={style.pageError}>{pageError}</div>
                </CenteredForm>
            </div>
        </React.Fragment>
    )
}

export default ResendSmsPage;