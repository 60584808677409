import * as React from 'react';
import { Navigate, useNavigate, useParams } from 'react-router';
import DataApi from '../../api/DataApi';
import ThreeStrikesApi, { TError, TIssuer, TObject, TOwner } from '../../api/ThreeStrikesApi';
import VignetApi from '../../api/VignetApi';
import AgreementBox from '../../components/agreement-box/AgreementBox';
import AlertBox, { ESeverity } from '../../components/alert-box/AlertBox';
import CenteredForm from '../../components/centered-form/CenteredForm';
import CountrySelectionField from '../../components/country-selection-field/CountrySelectionField';
import DownloadSummaryButton from '../../components/download-summary/DownloadSummary';
import KeyValuePair from '../../components/key-value-pair/KeyValuePair';
import LabeledInputField from '../../components/labeled-input-field/LabeledInputField';
import PageHeader from '../../components/page-header/PageHeader';
import TitleLineComponent from '../../components/page-layout/TitleLineComponent';
import PaymentTypeSelector from '../../components/payment-type-selector/PaymentTypeSelector';
import FormStepper, { TStep } from '../form-stepper/FormStepper';

import style from './ThreeStrikesRegistrationPage.module.css';

enum EProgress {
    AUTHENTICATION,
    PERSONAL_DETAILS,
    OBJECT_DETAILS,
    NEW_PASSWORD,
    PAYMENT,
    SUMMARY,
    THANK_YOU
}

const ThreeStrikesRegistrationPage = () => {

    const [email, setEmail] = React.useState<string>('');
    const [pincode, setPincode] = React.useState<string>('');

    const [progress, setProgress] = React.useState<EProgress>(EProgress.AUTHENTICATION);

    const [pageError, setPageError] = React.useState<string | undefined>(undefined);

    const [formData, setFormData] = React.useState({});

    const [hasPressedNext, setHasPressedNext] = React.useState<boolean>(false);

    const [owner, setOwner] = React.useState<TOwner | undefined>(undefined);
    const [object, setObject] = React.useState<TObject | undefined>(undefined);
    const [paymentMethods, setPaymentMethods] = React.useState<any | undefined>(undefined);

    const [password, setPassword] = React.useState<string>('');
    const [repeatPassword, setRepeatPassword] = React.useState<string>('');

    const [paymentMethod, setPaymentMethod] = React.useState<string>('');
    
    const [issuers, setIssuers] = React.useState<TIssuer[] | undefined>([]);
    const [issuer, setIssuer] = React.useState<string>('');

    const [hasAgreedUserAgreement, setHasAgreedUserAgreement] = React.useState<boolean>(false);
    const [hasAgreedDetailsCorrect, setHasAgreedDetailsCorrect] = React.useState<boolean>(false);

    const [processingRedirect, setProcessingReditect] = React.useState<boolean>(false);

    const [downloadInProgress, setDownloadInProgress] = React.useState<boolean>(false);
    const [downloadUrl, setDownloadUrl] = React.useState<string | undefined>(undefined);

    const navigate = useNavigate();

    const params = useParams();

    const loadAuthorizedData = (progress: EProgress) => {
        ThreeStrikesApi.getOwner((json: any)=> {
            setOwner(json);
            ThreeStrikesApi.getObject((json: any) => {
                setObject(json);
                ThreeStrikesApi.getIssuers((issuers: TIssuer[]) => {
                    setIssuers(issuers);
                    ThreeStrikesApi.getPaymentMethods((json: any) => {
                        setPaymentMethods(json);
                        setProgress(progress);
                        setHasPressedNext(false);
                        setProcessingReditect(false);
                    }, (error: Error) => {
                        setPageError(error.message);
                    })
                }, (error: Error) => {
                    setPageError(error.message);
                })
            }, (error: Error) => {
                setPageError(error.message);
            })
        }, (error: Error) => {
            setPageError(error.message);
        })
    }

    const getPaymentMethodNameById = (id: string) => {
        return paymentMethods?.find((paymentMethod: any) => {
            return paymentMethod.id === id;
        })?.name;
    }

    const getIssuerById = (id: string) => {
        return issuers?.find((issuer: any) => {
            return issuer.id === id;
        })?.name;
    }

    if(params.data && !email && !pincode) {
        setProcessingReditect(true);
        const data: any = JSON.parse(window.decodeURIComponent(window.atob(params.data)));
        console.log('Processing data:', data);
        setEmail(data.email);
        setPincode(data.pincode);
        setFormData(data.formData);
        setPassword(data.password);
        setRepeatPassword(data.password);
        setPaymentMethod(data.paymentMethod);
        setIssuer(data.issuer);
        if(params.status === 'done'){
            loadAuthorizedData(EProgress.THANK_YOU);
        }else{
            loadAuthorizedData(EProgress.SUMMARY);
            setPageError('Er is iets fout gegaan tijdens uw betaling.')
        }
    }

    if(processingRedirect) {
        return <div>Redirecting..</div>
    }

    const steps: TStep[] = [
        { text: 'Inloggen' },
        { text: 'Uw gegevens' },
        { text: 'BHG-gegevens' },
        { text: 'Nieuw wachtwoord' },
        { text: 'Betaalwijze' },
        { text: 'Samenvatting van uw aanvraag' },
        { text: 'Bedankt' }
    ]

    const isEmail = (value: string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    }

    const handleOnNext = () => {
        setPageError(undefined);
        setHasPressedNext(true);
        if (progress === EProgress.AUTHENTICATION) {
            if (!email) {
                setPageError('Het veld e-mailadres is niet ingevuld.');
                setHasPressedNext(false);
                return;
            }
            if (!isEmail(email)) {
                setPageError('Het e-mailadres in het veld hierboven is onjuist ingevuld.');
                setHasPressedNext(false);
                return;
            }
            if (!pincode) {
                setPageError('Het veld eenmalig wachtwoord is niet ingevuld.');
                setHasPressedNext(false);
                return;
            }

            ThreeStrikesApi.login(email, pincode, (accessToken: string)=>{
                setPageError(undefined);
                window.localStorage.setItem('access_token', accessToken);
                loadAuthorizedData(EProgress.PERSONAL_DETAILS);
            }, (error: TError) => {
                setHasPressedNext(false);
                if(error.status === 498) {
                    setPageError('De door u opgegeven inloggegevens zijn niet langer geldig.');
                    return;
                }
                setPageError('De combinatie e-mailadres en eenmalig wachtwoord is onjuist. Controleer de gegevens.');
            })
            return;
        }
        
        if (progress === EProgress.PERSONAL_DETAILS){
            setProgress(EProgress.OBJECT_DETAILS)
            setHasPressedNext(false);
            return;
        }

        if (progress === EProgress.OBJECT_DETAILS){
            setProgress(EProgress.NEW_PASSWORD);
            setHasPressedNext(false);
            return;
        }

        if (progress === EProgress.NEW_PASSWORD){
            if(owner?.bhg_account) {
                setPageError(undefined);
                setProgress(EProgress.PAYMENT);
                setHasPressedNext(false);
                return;
            }

            if(!password || password.trim().length === 0) {
                setPageError('U dient een wachtwoord aan te maken.');
                return;
            }

            if(password !== repeatPassword) {
                setPageError('De wachtwoorden komen niet overeen.');
                return;
            }

            ThreeStrikesApi.changePassword(password, (json: any) => {
                setProgress(EProgress.PAYMENT);
                setHasPressedNext(false);
            }, (error: Error) => {
                setPageError('Het door u opgegeven wachtwoord voldoet niet aan de eisen.');
            })
            return;
        }

        if (progress === EProgress.PAYMENT){
            if(!paymentMethod){
                setPageError('U moet een betaalmethode selecteren.')
                return;
            }

            if(!issuer){
                setPageError('U moet een bank selecteren.')
                return;
            }


            setProgress(EProgress.SUMMARY);
            setHasPressedNext(false);
        }

        if(progress === EProgress.SUMMARY){
            if(!hasAgreedUserAgreement || ! hasAgreedDetailsCorrect) {
                setPageError('Ga akkoord met het bovenstaande.');
                setHasPressedNext(false);
                return;
            }

            const data = {
                email: email,
                pincode: pincode,
                formData: formData,
                password: password,
                paymentMethod: paymentMethod,
                issuer: issuer
            }

            ThreeStrikesApi.performPayment(issuer, paymentMethod, data, (json: any) => {
                if(json.redirect_url){
                    window.location.href = json.redirect_url;
                }else if (json.request_number) {
                    setProgress(EProgress.THANK_YOU);
                }
                setHasPressedNext(false);
            }, (error: Error) => {
                setPageError(error.message);
                setHasPressedNext(false);
                return;
            })
        }

    }

    if (progress === EProgress.THANK_YOU) {
        const getThankYouHeader = () => {
            const months = ['Januari','Februari','Maart','April','Mei','Juni','Juli','Augustus','September','Oktober','November','December'];
            const date = new Date();
            const day = date.getDate();
            const month = months[date.getMonth()].toLocaleLowerCase();
            const year = date.getFullYear();
            return (<div id={style.subHeader}>
                    Wij hebben uw aanvraag ontvangen op {day} {month} {year}.
                    <br></br>
                    U ontvangt via e-mail een bevestiging van ons.
                </div>)
        }

        const getThankYouFooter = () => {
            return (<div id={style.subHeader}>Kijk op <a href='https://amsterdam.nl/varen'>amsterdam.nl/varen</a> of stuur een e-mail naar <a href="mailto: vaarvignet@amsterdam.nl">vaarvignet@amsterdam.nl</a>.
                <br></br>
                Noteer in uw e-mail altijd het vignetnummer.
            </div>)
        }

        const handleOnDownloadSummary = () => {
            window.open(downloadUrl);
        }

        if(!downloadInProgress && !downloadUrl && !pageError){
            setDownloadInProgress(true);
            const interval = setInterval(()=>{
                ThreeStrikesApi.getSummary((json: any)=>{
                    if(json?.summary_link) {
                        clearInterval(interval);
                        setDownloadInProgress(false);
                        setDownloadUrl(json.summary_link);
                    }
                }, (error: TError)=> {
                    clearInterval(interval);
                    setDownloadInProgress(false);
                    setPageError('Er is iets fout gegaan bij het ophalen van de samenvatting.');
                    console.error(error);
                })
            }, 1000);
        }

        return (
            <React.Fragment>
                <PageHeader className={style.pageHeader}></PageHeader>
                <div id={style.container}>
                    <CenteredForm>
                        <div id={style.header}>Bedankt</div>
                        {getThankYouHeader()}
                        <br></br>
                        <div className={style.pageSubHeader}>Samenvatting</div>
                        <div>
                            U kunt de samenvatting van uw aanvraag hieronder downloaden.
                        </div>
                        { !downloadInProgress && downloadUrl && !pageError ? <DownloadSummaryButton onClick={handleOnDownloadSummary}></DownloadSummaryButton> : <React.Fragment></React.Fragment> }
                        { downloadInProgress ? <React.Fragment><div id={style.downloadInProgressSpinnerText}>Wij zijn uw samenvatting aan het genereren.</div><div id={style.downloadInProgressSpinner}></div></React.Fragment> : <React.Fragment></React.Fragment> }
                        <div id={style.pageError}>{pageError}</div>
                        <br></br>
                        <div className={style.pageSubHeader}>Vragen?</div>
                        {getThankYouFooter()}
                    </CenteredForm>
                </div>
            </React.Fragment>
        )
    }

    if (progress === EProgress.SUMMARY) {
        return (
            <React.Fragment>
                <PageHeader className={style.pageHeader}></PageHeader>
                <div id={style.container}>

                    <FormStepper steps={steps} currentStep={5}></FormStepper>
                    <CenteredForm onPrevious={()=>{setPageError(undefined); setProgress(EProgress.PAYMENT)}} onNext={handleOnNext} locked={hasPressedNext} onNextText={'Naar betaling'}>
                        <TitleLineComponent text={"Aanvraag Binnenhavengeldvignet"} />
                        <div id={style.header}>Samenvatting</div>
                        <div id={style.subHeader}></div>

                        <KeyValuePair label={'Voorletters'} value={owner?.initials.value || ''}></KeyValuePair>
                        <KeyValuePair label={'Tussenvoegsel'} value={owner?.prefix.value || ''}></KeyValuePair>
                        <KeyValuePair label={'Achternaam'} value={owner?.lastname.value || ''}></KeyValuePair>
                        <KeyValuePair label={'BSN'} value={owner?.bsn.value || ''}></KeyValuePair>
                        <KeyValuePair label={'Straat'} value={owner?.address.value || ''}></KeyValuePair>
                        <KeyValuePair label={'Huisnummer'} value={owner?.number.value || ''}></KeyValuePair>
                        <KeyValuePair label={'Toevoeging'} value={owner?.additions.value || ''}></KeyValuePair>
                        <KeyValuePair label={'Postcode'} value={owner?.postcode.value || ''}></KeyValuePair>
                        <KeyValuePair label={'Woonplaats'} value={owner?.city.value || ''}></KeyValuePair>
                        <KeyValuePair label={'E-mailadres'} value={email || ''}></KeyValuePair>
                        <KeyValuePair label={'Telefoonnummer'} value={owner?.mobilephone.value || ''}></KeyValuePair>
                        <KeyValuePair label={'Naam boot'} value={object?.name.value || ''}></KeyValuePair>
                        <KeyValuePair label={'Breedte boot'} value={object?.width.value || ''}></KeyValuePair>
                        <KeyValuePair label={'Lengte boot'} value={object?.length.value || ''}></KeyValuePair>
                        <KeyValuePair label={'Soort motor'} value={object?.drive_type.value || ''}></KeyValuePair>
                        <KeyValuePair label={'Type vignet'} value={object?.vignet_type.value || ''}></KeyValuePair>
                        <KeyValuePair label={'Geselecteerde bank'} value={getIssuerById(issuer) || ''}></KeyValuePair>
                        <KeyValuePair label={'Betaalmethode'} value={getPaymentMethodNameById(paymentMethod) || ''}></KeyValuePair>
                        <KeyValuePair label={'Datum derde melding'} value={object?.start_date.value || ''}></KeyValuePair>
                        <KeyValuePair label={'Kosten tot 31 december'} value={object?.amount.value || ''}></KeyValuePair>

                        <AgreementBox defaultChecked={hasAgreedUserAgreement} validity={!hasPressedNext || hasAgreedUserAgreement} onChange={setHasAgreedUserAgreement} text={'Ik heb alle gegevens correct en volledig ingevuld. Ik begrijp dat een aanvraag via internet dezelfde juridische status heeft als een aanvraag met een geschreven geldige handtekening.'}></AgreementBox>
                        <AgreementBox defaultChecked={hasAgreedDetailsCorrect} validity={!hasPressedNext || hasAgreedDetailsCorrect} onChange={setHasAgreedDetailsCorrect} text={'Ik ben op de hoogte van de regels die op het water van Amsterdam gelden, zoals ze staan beschreven in de Binnenhavengeldverordening Pleziervaart en de Verordening op het binnenwater ('}><a href="https://amsterdam.nl/varen">amsterdam.nl/varen</a><span>). Bij het wijzigen of opzeggen van mijn vignet ga ik met deze regels akkoord.</span></AgreementBox>

                        <div id={style.pageError}>{pageError}</div>

                    </CenteredForm>
                </div>
            </React.Fragment>
        )
    }

    if (progress === EProgress.PAYMENT) {

        const handleOnChangePaymentMethod = (e: any) => {
            setPaymentMethod(e.target.value);
        }

        const handleOnChangeIssuer = (e: any) => {
            setIssuer(e.target.value);
        }

        return (
            <React.Fragment>
                <PageHeader className={style.pageHeader}></PageHeader>
                <div id={style.container}>

                    <FormStepper steps={steps} currentStep={4}></FormStepper>
                    <CenteredForm onPrevious={()=>{setPageError(undefined); setProgress(EProgress.NEW_PASSWORD)}} onNext={handleOnNext}>
                        <TitleLineComponent text={"Aanvraag Binnenhavengeldvignet"} />
                        <div id={style.header}>Machtiging afgeven</div>
                        <div id={style.subHeader}>
                            Met de machtiging geeft u de gemeente Amsterdam toestemming om het binnenhavengeld voor dit jaar en vanaf volgend jaar automatisch van uw rekening af te schrijven.
                            <br></br>
                            <br></br>
                            Wilt u uw vignet volgend jaar verlengen? Dan kunt u er nog steeds voor kiezen om het bedrag in 1 keer of in 4 delen te betalen. U kunt uw vignet natuurlijk ook opzeggen. Er wordt dan geen geld van uw rekening afgeschreven.
                        </div>
                        <div>
                            Dit jaar kunt u kiezen hoe u wilt betalen: in 1 keer of in 4 delen via een automatische afschrijving.
                        </div>
                        <br></br>
                        <div>
                            Maak uw keuze:
                        </div>
                        {
                            paymentMethods?.map((method: any, index: number) => {
                                return (
                                    <div key={index} className={!hasPressedNext || paymentMethod ? style.radioButtonContainerValid : style.radioButtonContainerInvalid}>
                                        <input checked={paymentMethod === method.id} className={style.radioButton} value={method.id} onChange={handleOnChangePaymentMethod} type={'radio'} name={'paymentType'}></input>
                                        <label className={style.radioButtonLabel}>{method.name}</label>
                                    </div>
                                )
                            })
                        }
                        <br></br>
                        <div>Selecteer uw bank:</div>
                        <select className={style.input} value={issuer} onChange={handleOnChangeIssuer}>
                            <option></option>
                            {
                                issuers?.map((issuer: TIssuer, index: number) => {
                                    return <option key={index} value={issuer.id}>{issuer.name}</option>
                                })
                            }
                        </select>

                        {/* <AgreementBox defaultChecked={hasAgreedPayment} validity={!hasPressedNext || hasAgreedPayment} text={'Ik geef de gemeente Amsterdam toestemming om volgend jaar de kosten van mijn vignet in 4 delen  van mijn rekening af te schrijven.'} onChange={setHasAgreedPayment}></AgreementBox> */}

                        <div id={style.pageError}>{pageError}</div>

                    </CenteredForm>
                </div>
            </React.Fragment>
        )
    }

    if (progress === EProgress.NEW_PASSWORD) {

        const characterCountCheck = () => {
            if(!password){return false};
            return password.length >= 12;
        }
    
        const maxLengthCheck = () => {
            if(!password){return false};
            return password.length <= 24;
        }
    
        const numberCheck = () => {
            if(!password){return false};
            return /\d\D*\d/.test(password)
        }
    
        const capitalizedCheck = () => {
            if(!password){return false};
            return password !== password.toLowerCase();
        }
    
        const specialCharacterCheck = () => {
            if(!password){return false};
            return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)
        }

        return (
            <React.Fragment>
                <PageHeader className={style.pageHeader}></PageHeader>
                <div id={style.container}>

                    <FormStepper steps={steps} currentStep={3}></FormStepper>
                    <CenteredForm onPrevious={()=>{setPageError(undefined); setProgress(EProgress.OBJECT_DETAILS)}} onNext={handleOnNext}>
                        <TitleLineComponent text={"Aanvraag Binnenhavengeldvignet"} />
                        <div id={style.header}>Maak een nieuw wachtwoord aan voor uw BHG-account</div>
                        { !owner?.bhg_account ?
                            <React.Fragment>
                                <div id={style.subHeader}>
                                    Dit account gebruikt u om in de toekomst uw gegevens te beheren.
                                </div>

                                <div>Uw wachtwoord moet aan de volgende eisen voldoen:</div>
                                <ul>
                                    <li className={characterCountCheck() ? style.validPasswordRule : style.invalidPasswordRule}>minimaal 12 tekens</li>
                                    <li className={numberCheck() ? style.validPasswordRule : style.invalidPasswordRule}>minimaal 2 cijfers</li>
                                    <li className={capitalizedCheck() ? style.validPasswordRule : style.invalidPasswordRule}>minimaal 1 hoofdletter</li>
                                    <li className={specialCharacterCheck() ? style.validPasswordRule : style.invalidPasswordRule}>minimaal 1 speciaal leesteken</li>
                                    <li className={maxLengthCheck() ? style.validPasswordRule : style.invalidPasswordRule}>maximaal 24 tekens</li>
                                </ul>

                                <div className={style.authenticationLabel}>Nieuw wachtwoord</div>
                                <input disabled={owner?.bhg_account} type='password' value={password} onChange={(e) => { setPassword(e.target.value) }}></input>
                                <div className={style.authenticationLabel}>Herhaal nieuw wachtwoord</div>
                                <input disabled={owner?.bhg_account} type='password' value={repeatPassword} onChange={(e) => { setRepeatPassword(e.target.value) }}></input>
                            </React.Fragment>
                        : <React.Fragment></React.Fragment>}
                        {
                            owner?.bhg_account === true ? <div className={style.bhgAlert}>
                                U heeft al een BHG-account. U hoeft hierdoor geen nieuw wachtwoord aan te maken. Klik op 'Volgende' om uw nieuwe vignet te betalen.
                            </div> : <React.Fragment></React.Fragment>
                        }

                        <div id={style.pageError}>{pageError}</div>

                    </CenteredForm>
                </div>
            </React.Fragment>
        )
    }
    
    if (progress === EProgress.OBJECT_DETAILS) {
        return (
            <React.Fragment>
                <PageHeader className={style.pageHeader}></PageHeader>
                <div id={style.container}>

                    <FormStepper steps={steps} currentStep={EProgress.OBJECT_DETAILS}></FormStepper>
                    <CenteredForm onPrevious={()=>{setPageError(undefined); setProgress(EProgress.PERSONAL_DETAILS)}} onNext={handleOnNext}>
                        <TitleLineComponent text={"Aanvraag Binnenhavengeldvignet"} />
                        <div id={style.header}>BHG-gegevens</div>
                        <div id={style.subHeader}>
                            Dit zijn de gegevens die wij van Nautisch Toezicht hebben ontvangen.
                            <br></br>
                            Als dit niet correct is neem contact op met <a href="mailto:nautischtoezicht@amsterdam.nl">nautischtoezicht@amsterdam.nl</a>.                            
                        </div>

                        <LabeledInputField key={'object_name'} predefinedData={object} disabled={object?.name.isEditable} validator={undefined} text={'Naam boot'} name={'name'} formData={formData}></LabeledInputField>
                        <LabeledInputField key={'object_width'} predefinedData={object} disabled={object?.width.isEditable} validator={undefined} text={'Breedte boot'} name={'width'} formData={formData}></LabeledInputField>
                        <LabeledInputField key={'object_length'} predefinedData={object} disabled={object?.length.isEditable} validator={undefined} text={'Lengte boot'} name={'length'} formData={formData}></LabeledInputField>

                        <div className={style.dropDownMenuLabel}>Type vignet</div>
                        <select className={style.dropDownMenu} disabled={!object?.vignet_type.isEditable}>
                            <option>{object?.vignet_type.value}</option>
                        </select>

                        <div className={style.dropDownMenuLabel}>Soort motor</div>
                        <select className={style.dropDownMenu} disabled={!object?.drive_type.isEditable}>
                            <option>{object?.drive_type.value}</option>
                        </select>

                        <LabeledInputField key={'object_date'} predefinedData={object} disabled={object?.start_date.isEditable} validator={undefined} text={'Datum derde melding'} name={'start_date'} formData={formData}></LabeledInputField>
                        <LabeledInputField key={'object_cost'} predefinedData={object} disabled={object?.amount.isEditable} validator={undefined} text={'Kosten tot 31 december'} name={'amount'} formData={formData}></LabeledInputField>

                        <div id={style.pageError}>{pageError}</div>

                    </CenteredForm>
                </div>
            </React.Fragment>
        )
    }

    if (progress === EProgress.PERSONAL_DETAILS) {
        return (
            <React.Fragment>
                <PageHeader className={style.pageHeader}></PageHeader>
                <div id={style.container}>

                    <FormStepper steps={steps} currentStep={EProgress.PERSONAL_DETAILS}></FormStepper>
                    <CenteredForm onPrevious={()=>{setPageError(undefined); setProgress(EProgress.AUTHENTICATION)}} onNext={handleOnNext}>
                        <TitleLineComponent text={"Aanvraag Binnenhavengeldvignet"} />
                        <div id={style.header}>Uw gegevens</div>
                        <div id={style.subHeader}>
                            Dit zijn uw gegevens van uw doorvaartvignet die bekend zijn bij ons.
                            <br></br>
                            Na afronding kunt u uw adresgegevens aanpassen.
                        </div>

                        <LabeledInputField predefinedData={owner} disabled={owner?.initials.isEditable} validator={undefined} text={'Voorletters'} name={'initials'} formData={formData}></LabeledInputField>
                        <LabeledInputField predefinedData={owner} disabled={owner?.prefix.isEditable} validator={undefined} text={'Tussenvoegsel'} name={'prefix'} formData={formData}></LabeledInputField>
                        <LabeledInputField predefinedData={owner} disabled={owner?.lastname.isEditable} validator={undefined} text={'Achternaam'} name={'lastname'} formData={formData}></LabeledInputField>
                        <LabeledInputField predefinedData={owner} disabled={owner?.bsn.isEditable} validator={undefined} text={'BSN'} name={'bsn'} formData={formData}></LabeledInputField>
                        <LabeledInputField predefinedData={owner} disabled={owner?.address.isEditable} validator={undefined} text={'Straat'} name={'address'} formData={formData}></LabeledInputField>
                        <LabeledInputField predefinedData={owner} disabled={owner?.number.isEditable} validator={undefined} text={'Huisnummer'} name={'number'} formData={formData}></LabeledInputField>
                        <LabeledInputField predefinedData={owner} disabled={owner?.additions.isEditable} validator={undefined} text={'Toevoeging'} name={'additions'} formData={formData}></LabeledInputField>
                        <LabeledInputField predefinedData={owner} disabled={owner?.postcode.isEditable} validator={undefined} text={'Postcode'} name={'postcode'} formData={formData}></LabeledInputField>
                        <LabeledInputField predefinedData={owner} disabled={owner?.postcode.isEditable} validator={undefined} text={'Woonplaats'} name={'city'} formData={formData}></LabeledInputField>

                        <LabeledInputField predefinedData={owner} disabled={owner?.postcode.isEditable} validator={undefined} text={'E-mailadres'} name={'email'} formData={formData}></LabeledInputField>
                        
                        <LabeledInputField predefinedData={owner} disabled={owner?.postcode.isEditable} validator={undefined} text={'Telefoonnummer'} name={'mobilephone'} formData={formData}></LabeledInputField>
                        
                        <div id={style.pageError}>{pageError}</div>

                    </CenteredForm>
                </div>
            </React.Fragment>
        )
    }

    if (progress === EProgress.AUTHENTICATION) {
        return (
            <React.Fragment>
                <PageHeader className={style.pageHeader}></PageHeader>
                <div id={style.container}>

                    <FormStepper steps={steps} currentStep={EProgress.AUTHENTICATION}></FormStepper>
                    <CenteredForm onNext={handleOnNext} locked={hasPressedNext}>
                        <TitleLineComponent text={"Aanvraag Binnenhavengeldvignet"} />
                        <div id={style.header}>E-mailadres en eenmalig wachtwoord invullen</div>
                        <div id={style.subHeader}>
                            Vul hieronder uw eenmalig wachtwoord in. Het eenmalig wachtwoord heeft u via een sms ontvangen.
                        </div>

                        <div className={style.authenticationLabel}>E-mailadres</div>
                        <input type='text' value={email} onChange={(e) => { setEmail(e.target.value) }} onKeyDown={(e)=>{if(e.key === 'Enter'){handleOnNext()}}} className={!hasPressedNext || email ? style.valid : style.invalid}></input>
                        <div className={style.authenticationLabel}>Eenmalig wachtwoord</div>
                        <input type='password' value={pincode} onChange={(e) => { setPincode(e.target.value) }} className={!hasPressedNext || pincode ? style.valid : style.invalid} onKeyDown={(e)=>{if(e.key === 'Enter'){handleOnNext()}}}></input>

                        <div id={style.smsResendButton}><label onClick={()=>{navigate('/resend-sms')}}>Verstuur eenmalig wachtwoord opnieuw.</label></div>

                        <div id={style.pageError}>{pageError}</div>

                        <div id={style.disclaimer}>
                            De gemeente Amsterdam gaat zorgvuldig en veilig met uw persoonsgegevens om. Wij houden ons daarbij aan de privacywetten en -regels. Dat betekent bijvoorbeeld dat wij uw gegevens alleen voor deze aanvraag gebruiken en niet voor iets anders. Meer informatie vindt u in de <a href='https://www.amsterdam.nl/privacy/specifieke/privacyverklaring-parkeren-verkeer-bouw/verkeersmanagement-gracht-privacy/'>privacyverklaring Binnenhavengeld</a>.
                        </div>

                    </CenteredForm>
                </div>
            </React.Fragment>
        )
    }

    return (
        <div>403 Forbidden.</div>
    )
}

export default ThreeStrikesRegistrationPage;