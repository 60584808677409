import * as React from 'react';

const TitleLineComponent = (props: any) => {
    return (
        <div>
            <b>{props.text}</b> <br />
            <div style={{ backgroundColor: 'black', height: '2px', width: '100%', marginBottom: '20px', marginTop: '10px' }}></div>
        </div>
    )
}

export default TitleLineComponent;